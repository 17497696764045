import React, {Component} from 'react';
import './HModal.css';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress
} from "@material-ui/core";

export default class HModal extends Component {

    constructor(props){
        super(props);

        this.state={
            displayed:false,
            title:"",
            body:null,
            warning:null,
            cancelable:true,
            cancelButton:null,
            okButton:null,
            secondOKButton:null,
            loadable:false,
            loading:false,
            loadingText:"",
            innerHTML:null,
            form:null,
        };

        global.emitter.addListener('showHModal', (args)=>{

            let elements = this.handleArgs(args);

            this.setState({
                displayed:true,
                title:elements.title,
                body:elements.body,
                warning:elements.warning,
                cancelable:elements.cancelable,
                cancelButton:elements.cancelButton,
                okButton:elements.okButton,
                secondOkButton:elements.secondOkButton,
                loadable:elements.loadable,
                loadingText:elements.loadingText,
                loading:false,
                innerHTML:elements.innerHTML,
                form:elements.form,
            })
        });

        global.emitter.addListener('HModalAsyncTaskDone', ()=>{
            this.setState({
                displayed:false,
                loading:false,
            })
        })

        global.emitter.addListener('programmaticallyTryHModalFinishButton', ()=>{
            this.onFinishClick();
        })
    }

    handleArgs(args){

        let elements = {
            title:"------",
            body:null,
            warning:null,
            cancelable:true,
            cancelButton:{
                text:"Fermer"
            },
            okButton:null,
            secondOkButton:null,
            loadable:false,
            loadingText:"Opération en cours, veuillez patienter ...",
            innerHTML:null,
            form:null,
        };

        if(args.title){
            elements.title = args.title
        }
        if(args.body){
            elements.body = args.body
        }
        if(args.warning){
            elements.warning = args.warning
        }
        if(args.cancelable){
            elements.cancelable = args.cancelable
        }
        if(args.cancelButtonText){
            elements.cancelButton = {
                text:args.cancelButtonText
            }
        }
        if(args.okButtonText){
            elements.okButton = {
                text:args.okButtonText
            }
        }
        if(args.secondOkButtonText){
            elements.secondOkButton = {
                text:args.secondOkButtonText
            }
        }
        if(args.loadable){
            elements.loadable = args.loadable
        }
        if(args.loadingText){
            elements.loadingText = args.loadingText
        }
        if(args.innerHTML){
            elements.innerHTML = args.innerHTML
        }
        if(args.form){
            elements.form = args.form
        }

        return elements;
    }

    emitResponse(response){
        if(this.state.loadable === false){
            this.setState({
                displayed:false
            },()=>{
                global.emitter.emit("HModalResponse",{cancel:false,result:response})
            })
        }
        else{
            this.setState({
                loading:true,
                cancelable:false,
            },()=>{
                global.emitter.emit("HModalResponse",{cancel:false,result:response})
            })
        }
    }

    cancelHModal(){
        this.setState({
            displayed:false
        },()=>{
            global.emitter.emit("HModalResponse",{cancel:true})
        })
    }

    onRequestClose(){
        if(this.state.cancelable === true){
            this.cancelHModal();
        }
    }

    render(){

        if(this.state.displayed === false){
            return null
        }

        return(

            <div style={{position:'fixed', top:0, right:0, width:0, height:0, zIndex:4000}}>
                <Dialog
                    open={this.state.displayed}
                    onClose={()=>{this.onRequestClose()}}

                aria-labelledby="h-alert-dialog-title"
                    aria-describedby="h-alert-dialog-description"
                >
                    <DialogTitle id="h-alert-dialog-title">{this.state.title}</DialogTitle>
                    <DialogContent>

                        {this.state.loading === false && !this.state.innerHTML &&
                            <DialogContentText id="h-alert-dialog-description">
                                {this.state.body}

                                {/*WARNING*/}
                                {(this.state.warning && this.state.body) ? [<br key={"0"}/>,<br key={"1"}/>] : null}
                                {this.state.warning && <span style={{color:'#EE3E3B', fontWeight:"bold"}}>{this.state.warning}</span>}

                            </DialogContentText>
                        }

                        {this.state.loading === false && this.state.innerHTML &&
                            <div style={{width:550}}>
                                {this.state.innerHTML}
                            </div>
                        }

                        {this.state.loading === false && this.state.form &&
                        <div style={{width:550, marginTop:15}}>
                            {this.state.form}
                        </div>
                        }


                        {this.state.loading === true &&
                            <div style={{height:50, width:550, borderRadius:6, border:"1px solid #CCCCCC", display:"flex",
                            flexDirection:"row", alignItems:'center', justifyContent:"flex-start", paddingLeft:10, backgroundColor:'#FAFAFA', marginBottom:20}}>
                                <CircularProgress size={30} style={{color:'#1079FC'}}/>
                                <p style={{flex:1, margin:0, marginLeft:10, textAlign:"left", color:'#555555'}}>{this.state.loadingText}</p>
                            </div>
                        }

                    </DialogContent>
                    {
                        this.state.loading === false &&
                        <DialogActions>
                            {this.state.cancelButton &&
                            <Button onClick={()=>{this.cancelHModal()}} color="primary">
                                {this.state.cancelButton.text}
                            </Button>
                            }
                            {this.state.okButton &&
                            <Button onClick={()=>{this.onFinishClick("Ok")}} color="primary">
                                {this.state.okButton.text}
                            </Button>
                            }
                            {this.state.secondOkButton &&
                            <Button onClick={()=>{this.onFinishClick("SecondOk")}} color="primary">
                                {this.state.secondOkButton.text}
                            </Button>
                            }
                        </DialogActions>
                    }

                </Dialog>
            </div>
        )
    }

    onFinishClick(value){
        if(this.state.form){
            let formValidationListener:any;
            formValidationListener = global.emitter.addListener("requestHFormValidationResponse",(data)=>{
                formValidationListener.remove();
                if(data.valid === true){
                    this.emitResponse({formResult:data.form});
                }
            });
            global.emitter.emit("requestHFormValidation");
        }
        else{
            this.emitResponse({fromButton:value})
        }
    }
}
