import React, {useEffect, useState} from 'react'
import {sidebarData, sideBarDataDownload} from "../AppBar/SidebarData"
import Icon from "@material-ui/core/Icon";
import { NavLink} from "react-router-dom";
import './Navbar.css'
import Logo from '../../assets/svg/GTFS_Comparator_Logo.svg'
import RenderBusLottie from "./RenderBusLottie";
import Tooltip from "@material-ui/core/Tooltip";

export default function Sidebar () {

    useEffect(() => {
        const listener = global.emitter.addListener('showAgencyName', (agencyName) => {
            setAgencyName(agencyName)
        })
        return () => listener.remove()
    }, [])

    const [agencyName, setAgencyName] = useState(global.agencyName)

    return (
        <div className={'wrapper-navbar'}
            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 250, backgroundColor: '#2F3137', height: '100%'}}>
            <div style={{height: 200, width: '100%', backgroundColor: '#202225', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img src={Logo} alt={'Logo Gtfs Comparator'} style={{width: 60, height: 'auto', marginBottom: 10}}/>
            <p style={{margin: 0, color: '#E6E6E6', fontFamily: 'Oswald', fontSize: 22}}>GTFS</p>
            <span style={{margin: 0, color: '#E6E6E6', fontFamily: 'Oswald', fontSize: 18}}>COMPARATOR</span>
            </div>
            {/*<RenderBusLottie />*/}
            <div style={{width: '100%', flex:1 , padding: 10, borderRadius: '8px', marginTop: 30, }}>
                {sidebarData.map((item, index) => {
                    return (
                        renderNavlink(item, index)
                    )
                })}
                <div style={{width: 210, height: 1, backgroundColor: "#636363", marginTop: 10, marginBottom: 10, marginLeft: 10}} />
                {sideBarDataDownload.map((item, index) => {
                    return (
                        renderDownloadLink(item, index)
                    )
                })}
            </div>

            {/*<div style={{*/}
            {/*    margin: '1em', display: 'flex', width: '40px', height: '40px', borderRadius: '50%', background: '#0F74F2',*/}
            {/*    cursor: 'pointer', justifyContent: 'center', alignItems: 'center'*/}
            {/*}}*/}
            {/*    // onClick={() => }*/}
            {/*     className={"hover-button-add"}*/}
            {/*>*/}
            {/*    <Tooltip title={"Ajouter un nouveau GTFS"} placement={"bottom"}>*/}
            {/*        <Icon style={{color: '#FFF', fontSize: 22, display: 'inline-block', textAlign: 'center'}}>*/}
            {/*            add*/}
            {/*        </Icon>*/}
            {/*    </Tooltip>*/}
            {/*</div>*/}
        </div>
    )

    function renderNavlink (item, index) {
        if (item.requireGTFS && agencyName === 'empty') {
            return (
                <Tooltip key={`m̀enu: ${index}`} title={"Impossible d'y accéder veuillez au préalable insérer un GTFS"} placement={"right"}>
                <div style={{height: 40, width: '100%', textDecoration: 'none', cursor: 'not-allowed', opacity: 0.3}}>
                    <div className={'nav-text'}>
                        <Icon style={{fontSize: 24, paddingLeft: 10}}>{item.icon}</Icon>
                        <p style={{margin: 0, fontSize: 16, paddingLeft: 10}}>{item.title.toUpperCase()}</p>
                    </div>
                </div>
                    </Tooltip>
            )
        } else {
        return (
            <NavLink to={item.path} key={`m̀enu: ${index}`} activeClassName={"active-nav-drawer-link"}
                     style={{height: 40, width: '100%', textDecoration: 'none', cursor: 'pointer'}}>
                <div className={'nav-text'}>
                    <Icon style={{fontSize: 24, paddingLeft: 10}}>{item.icon}</Icon>
                    <p style={{margin: 0, fontSize: 16, paddingLeft: 10}}>{item.title.toUpperCase()}</p>
                </div>
            </NavLink>
        )
        }
    }

    function renderDownloadLink (item, index) {
        if (item.requireGTFS && agencyName === 'empty') {
            return (
                <Tooltip key={`downloard: ${index}`} title={"Impossible d'y accéder veuillez au préalable insérer un GTFS"} placement={"right"}>
                    <div style={{height: 40, width: '100%', textDecoration: 'none', cursor: 'not-allowed', opacity: 0.3}}>
                        <div className={'nav-text'}>
                            <Icon style={{fontSize: 24, paddingLeft: 10}}>{item.icon}</Icon>
                            <p style={{margin: 0, fontSize: 16, paddingLeft: 10}}>{item.title.toUpperCase()}</p>
                        </div>
                    </div>
                </Tooltip>
            )
        } else {
            return (
                <div onClick={item.actionDownload} key={`downloard: ${index}`}
                     style={{height: 40, width: '100%', textDecoration: 'none', cursor: 'pointer'}}>
                    <div className={'nav-text'}>
                        <Icon style={{fontSize: 24, paddingLeft: 10}}>{item.icon}</Icon>
                        <p style={{margin: 0, fontSize: 16, paddingLeft: 10}}>{item.title.toUpperCase()}</p>
                    </div>
                </div>
            )
        }
    }
}