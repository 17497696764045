import React, {useState} from "react"
import Dropzone from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import onDropImage from "../../../assets/undraw/dropImage.svg";

export default function DropCard({addGTFS}) {

    const [file, setFile] = useState([]);
    const [isUploaded, setIsUploaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    async function onDrop(file) {
        setIsLoading(true)
        try {
            await addGTFS(file)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.warn(error)
        }
        setFile(file)
    }

    return (
        <Dropzone
            style={{width: "100%", height: '100%'}}
            accept={".zip"}
            onDrop={(accepted) => {
                onDrop(accepted)
            }}
        >
            {({getRootProps, getInputProps, isDragActive}) => (
                <div {...getRootProps({className: 'dropzone'})}
                     style={{
                         width: "100%", height: 300, display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center',
                         border: isDragActive ? '2px dashed #1079FC' : '2px dashed #E6E6E6'
                     }}>
                    {isLoading ?
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <CircularProgress/>
                            <p style={{color: "#CCCCCC", margin: 0, marginTop: 20, fontSize: 14, fontWeight: 'bold'}}>
                                Chargement en cours
                            </p>
                        </div>
                        :
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img src={onDropImage} style={{height: 100, opacity: 1}} alt={''}
                            />
                            <p style={{color: "#6E85D2", margin: 0, width: 300, textAlign: "center", marginTop: 20, fontSize: 14, fontWeight: 'bold', opacity: 1}}>
                                Glisser déposer votre GTFS zippé ou cliquer
                            </p>
                            {!isUploaded &&
                            <div style={{width: 300, textAlign: "center", marginTop: 20, fontSize: 14, opacity: isDragActive ? 0 : 1}}>
                                <p style={{color: "#CCCCCC", margin: 0,}}>
                                    Accepte un seul fichier en .zip
                                </p>
                            </div>
                            }
                        </div>
                    }
                    {isUploaded &&
                    <div
                        style={{
                            backgroundColor: "#26AC48",
                            marginTop: 10,
                            width: 250,
                            padding: 10,
                            borderRadius: 4,
                            boxShadow: "0 0 4px rgba(0, 0, 0, .14), 0 4px 4px rgba(0, 0, 0, .28)"
                        }}>
                        <p style={{color: "#FFFFFF", margin: 0, textAlign: "center", fontSize: 14, fontWeight: "bold"}}>Fichier enregistré avec succès</p>
                    </div>
                    }
                    <input {...getInputProps()} />

                </div>
            )}
        </Dropzone>
    )
}