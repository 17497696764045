import React, {Component} from 'react'
import Button from '@material-ui/core/Button';
import {Helpers} from "./Helpers";
import GTFSAPI from "./api/GTFSAPI";
import "./HomePage.css"
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import DropCard from "./Components/DropCard";
import {Tools} from "../shared/Tools";
import LinearProgress from "@material-ui/core/LinearProgress";
import RenderRowResult from "./Components/RenderRowResult";

class ResultGTFS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            GTFS_1: "",
            data: [],
            warnings: [],
            errors: [],
            isSelected: []
        }
    }

    listenerToReset
    listenerToAddGTFS

    componentDidMount() {
        this.checkGTFS()

        this.listenerToReset = global.emitter.addListener("reset", () => {
            this.setNewGTFS()
        })
        this.listenerToAddGTFS = global.emitter.addListener("addGTFS", () => {
            this.setNewGTFS()
        })


    }

    async checkGTFS() {
        this.setState({isLoading: true})
        try {
            global.agencyName = await GTFSAPI.checkIsNoGTFSInDatabase()
            if (global.agencyName !== true) {
                const data = await GTFSAPI.validateGTFSInBase()
                const filterRes = Helpers.filterRes(data)
                console.log(filterRes, "filterRes")
                console.log(data, 'data')
                this.setState({data: data, GTFS_1: data.agencyName, isLoading: false, errors: filterRes[0], warnings: filterRes[1]})
                global.emitter.emit("showAgencyName", data.agencyName);
            } else {
                this.setState({GTFS_1: "empty", isLoading: false})
                global.emitter.emit("showAgencyName", 'empty');
            }
        } catch (error) {
            console.warn(error)
        }
    }

    selectDetails(item) {
        const data = this.state.isSelected.slice()
        if (data.indexOf(item) === -1) {
            data.push(item)
        } else {
            data.splice(data.indexOf(item), 1)
        }
        this.setState({isSelected: data})
    }

    render() {
        const isNotAllowed = this.state.GTFS_1 === "empty" || this.state.data.hasErrors
        return (

            <div className={"Card_GTFS"}>

                {/*Header*/}
                <div style={{display: 'flex', flexDirection: "column"}}>
                    <div style={{
                        height: 60, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: "#202225", borderTopLeftRadius: 8, borderTopRightRadius: 8,
                    }}>
                        <p style={{fontSize: 30, paddingLeft: 10, color: "#af3a34", flex: 1, fontWeight: 'bold'}}>GTFS
                            <span style={{fontWeight: 'normal', paddingLeft: 10, fontSize: 24}}>{this.state.GTFS_1}</span></p>
                    </div>
                    {this.state.isLoading &&
                    <LinearProgress style={{width: "100%"}}/>
                    }
                </div>
                {/*End Header*/}

                <div style={{}} className={"result"}>

                    {/*ShowResult*/}
                    {this.state.GTFS_1 !== "empty" &&
                    <div>
                        {/*<div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>*/}
                        {/*    <RenderLottieFile*/}
                        {/*        errors={this.state.data.hasErrors}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {this.state.data.hasErrors ?
                            <div>
                                <p style={{fontSize: 20, color: "white", textAlign: 'center'}}>Le GTFS contient les erreurs ci-dessous</p>

                                {this.state.errors.length > 0 && this.state.errors.map((el, index) => {
                                    return (
                                        <div key={index}
                                             onClick={() => this.selectDetails(el[0])}>
                                            <RenderRowResult
                                                item={el}
                                                data={this.state.errors}
                                                index={index}
                                                deleteErrors={(error) => this.deleteErrors(error)}
                                                selectedRow={this.state.isSelected}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            : <div>

                                {this.state.data.hasWarnings ?
                                    <div>
                                        <p style={{fontSize: 20, color: "white", textAlign: 'center'}}>Le GTFS est valide mais contient les avertissements ci-dessous</p>
                                        {this.state.warnings.length > 0 && this.state.warnings.map((el, index) => {
                                            return (
                                                <div key={index} onClick={() => this.selectDetails(el[0])}>
                                                    <RenderRowResult
                                                        item={el}
                                                        data={this.state.warnings}
                                                        index={index}
                                                        selectedRow={this.state.isSelected}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : <div>
                                        <p style={{fontSize: 20, color: "white", textAlign: 'center'}}>Le GTFS est valide</p>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    }
                    {this.state.GTFS_1 === "empty" &&
                    // ShowInputGTFS
                    <div style={{padding: 30}}>
                        <DropCard
                            addGTFS={(file) => this.addGTFS(file)}
                        />
                    </div>
                    }
                </div>

            </div>
        )
    }

    async setNewGTFS() {
        try {
            await this.reset()
            await GTFSAPI.clearDatabase()
        } catch (e) {
            console.warn(e)
        }
    }


    async deleteErrors(error) {
        try {
            await Helpers.deleteErrors(error)
            Tools.showSuccessSnack("Suppression", "Erreurs supprimées avec succès")
            await this.checkGTFS()
        } catch (e) {
            console.warn(e)
            Tools.showErrorSnack("Erreur", "Une erreur est intervenue lors de la suppression")
        }
    }

    reset() {
        global.emitter.emit("showAgencyName", 'empty');
        this.setState({
            isLoading: false,
            GTFS_1: "empty",
            data: [],
            warnings: [],
            errors: [],
            isSelected: []
        })
    }

    async addGTFS(file) {
        try {
            const data = await GTFSAPI.validateGTFS((file[0]))
            const filterRes = Helpers.filterRes(data)
            await this.setState({data: data, GTFS_1: data.agencyName, errors: filterRes[0], warnings: filterRes[1]})
            global.emitter.emit("showAgencyName", data.agencyName);
            console.log(data, "raw data")
            console.log(filterRes, "filter data")
        } catch (errors) {
            console.warn(errors)
            await this.reset()
            Tools.showErrorSnack('Erreur', `Une erreur est intervenue lors de l'ajout du GTFS`)
        }
    }

}

export default ResultGTFS;