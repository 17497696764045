export default class Tools {

    static translatePathname(pathname) {
        const path = pathname.substring(1, pathname.length)
        if (path === 'home') {
            return 'Accueil'
        }
        if (path === 'calendar') {
            return 'Calendrier'
        }
        if (path === 'filter/routes') {
            return 'Routes'
        }
    }
}