import React from 'react';
import {ButtonBase, Icon} from "@material-ui/core";

function RouteCard({route, routesSelected, index, handleRoutes}) {

    const isSelected = routesSelected.map(route => route.id).indexOf(route.id) !== -1
    return (
        <div onClick={() => handleRoutes(route)}
            style={{width: '100%', display: 'flex', flexDirection: 'row', height: 50, alignItems: 'center', borderTop: index !== 0 && "1px solid #636363", cursor: 'pointer' }}>
            <div style={{
                height: 24, minWidth: 24, borderRadius: 4, backgroundColor: '#' + route.route_color, display: 'flex', marginLeft: 20,
                justifyContent: 'center', alignItems: 'center', border: '1px solid #222222', paddingLeft: 4, paddingRight: 4, marginTop: -1,
            }}>
                <span style={{color: '#FFFFFF', fontSize: 13, zIndex: 5}} className={"text-bordered"}>{route.route_short_name}</span>
            </div>
            <p style={{fontSize: 13, color: "#E6E6E6", fontWeight: 'bold', margin: 0, paddingLeft: 10, flex: 1}}>{route.route_long_name}</p>
            <ButtonBase style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: 'center', marginRight: 20}}>
                <Icon style={{color: isSelected ? "#1079FC" : "#CCCCCC", fontSize: 18}}>
                    {isSelected ? "check_box" : "check_box_outline_blank"}
                </Icon>
            </ButtonBase>
        </div>
    );
}

export default RouteCard;