import React, {useEffect, useState} from 'react';
import CalendarTable from "./CalendarTable";
import {CalendarAPI} from "./api/CalendarAPI";
import moment from "moment";
import HTitle from "../shared/HTitle";

export default function Calendar(props) {

    useEffect(() => {
        getAllCalendars()
    }, [])

    const [calendars, setCalendars] = useState([])

    function getAllCalendars() {
        CalendarAPI.getCalendar()
            .then((calendars) => {

                for (let calendar of calendars) {
                    calendar.prettyStartDate = moment(calendar.start_date).format("DD/MM/YYYY")
                    calendar.prettyEndDate = moment(calendar.end_date).format("DD/MM/YYYY")
                }
                setCalendars(calendars)
            });
    }

    return (
        <div style={{width: "100%", height: "100%", backgroundColor: '#36393E'}}>
            <HTitle title={props.location.pathname}/>
            <div style={{
                display: 'flex', flexDirection: 'row', width: '100%', height: "calc(100% - 60px)"
            }}>
                <CalendarTable
                    calendars={calendars}
                    onShouldReloadCalendars={() => {
                        getAllCalendars()
                    }}
                />
            </div>
        </div>
    )

}