import moment from "moment";

export class Tools {

    static showSuccessSnack(title, body) {
        global.emitter.emit('showSnackBar', {
            title: title,
            body: body,
            type: 'success'
        })
    }

    static showErrorSnack(title, body) {
        global.emitter.emit('showSnackBar', {
            title: title,
            body: body,
            type: 'error'
        });
    }

    static showLoadDataErrorSnack() {
        global.emitter.emit('showSnackBar', {
            title: "Erreur",
            body: "Une erreur est survenue lors du chargement des données",
            type: 'error'
        });
    }

    static showOperationErrorSnack() {
        global.emitter.emit('showSnackBar', {
            title: "Erreur",
            body: "Une erreur est survenue lors de l'opération",
            type: 'error'
        });
    }

    static unConcat(str: string) {

        if (str === "") return [];
        try {
            let res = str.split("\u001d").map(e => e.split("\u001f").map(e => {
                return e === "\u0000" ? null : e
            }));
            if (res.length) {
                if (res.filter(e => e.length !== res[0].length).length) throw "Tools::unConcat() [Hubup catched exception] Inconsistency detected while decoding " + str + ".\n concatenation in inconsistent, backend result badly formatted.";
            }
            return res;
        } catch (error) {
            console.log(str, "this is not a valid string (Tools::unConcat)");
            return [];
        }

    }

    static getDefaultLatLng() {

        let serverParameters = global.serverParameters;

        let defaultLat = null;
        let defaultLng = null;

        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "server_default_lat") {
                    defaultLat = parseFloat(parameter.param_str_value);
                }
                if (parameter.param_key === "server_default_lng") {
                    defaultLng = parseFloat(parameter.param_str_value);
                }
            }
        }

        if (defaultLat && defaultLng) {
            return ({lat: defaultLat, lng: defaultLng})
        } else {
            return ({lat: 48.834982, lng: 2.358239})
        }
    }

    static getContrastYIQ(hexcolor) {
        if (hexcolor.length === 7) {
            hexcolor = hexcolor.substring(1);
        }

        let r = parseInt(hexcolor.substr(0, 2), 16);
        let g = parseInt(hexcolor.substr(2, 2), 16);
        let b = parseInt(hexcolor.substr(4, 2), 16);
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 140) ? '#333333' : 'white';
    }

    static getDistanceFromLatLngs(p1, p2): number {
        let lat1 = p1.lat;
        let lng1 = p1.lng;
        let lat2 = p2.lat;
        let lng2 = p2.lng;

        let pi80 = Math.PI / 180;
        lat1 *= pi80;
        lng1 *= pi80;
        lat2 *= pi80;
        lng2 *= pi80;

        /**
         * originally used value : 6372.797
         * Since we're operating around the 48" lat value, I selected a more accurate value here :
         * https://rechneronline.de/earth-radius/
         *
         * @type {number}
         */
        let r = 6366.371;
        let dlat = lat2 - lat1;
        let dlng = lng2 - lng1;
        let a = Math.sin(dlat / 2) * Math.sin(dlat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let km = r * c;

        return (km * 1000)
    }

    static getPolylineBounds(track_points) {
        return {
            minLat: Math.min.apply(null, track_points.map(function (d) {
                return d.latitude
            })),
            maxLat: Math.max.apply(null, track_points.map(function (d) {
                return d.latitude
            })),
            minLng: Math.min.apply(null, track_points.map(function (d) {
                return d.longitude
            })),
            maxLng: Math.max.apply(null, track_points.map(function (d) {
                return d.longitude
            }))
        }
    }

    static getPolylineLength(track_points) {
        let result = 0;
        for (let i = 0; i < track_points.length - 1; i++) {
            result = result + Tools.getDistanceFromLatLngs(track_points[i], track_points[i + 1])
        }
        return result;
    }

    static polylineToGeoJSON(decodedPolyline) {
        let coordinates = decodedPolyline.map((position) => {
            return ([position.lng, position.lat])
        })
        return ({
            type: 'geojson',
            data: {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates
                }
            }
        })
    }

    static isEmpty(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }


    static parseISODate(s: string) {
        return moment(s).toDate();
    }

    static getPrettyDistance(dist) {
        let unit = dist < 1000 ? "m" : "km";
        if (dist < 1000) {
            let meters = Math.round(dist / 10) * 10;
            return meters + ' ' + unit;
        } else {
            let kilometers = Math.round(dist / 100) / 10;
            return kilometers + ' ' + unit
        }
    }

    static getPrettyDuration(dur) {

        let hours = Math.floor(dur / 60);
        let minutes = Math.floor(dur - hours * 60);

        let result = minutes + " min";

        if (hours > 0) {
            result = hours + " h " + result;
        }
        return result;
    }

    static getPrettyLongDuration(dur) {

        let days = Math.floor(dur / (60 * 24));
        let hours = Math.floor((dur - days * 60 * 24) / 60);
        let minutes = Math.floor(dur - days * 60 * 24 - hours * 60);

        let result = minutes + " min";
        if (hours > 0) {
            result = hours + " h " + result;
        }
        if (days > 0) {
            result = days + " j " + result;
        }
        return result;
    }

    static withoutMillisIsoString(date) {
        return date.toISOString().split('.')[0] + "Z";
    }

    static sortGeoliteRoutes(rawRoutes) {

        let numberedRoutes = [];
        let textedRoutes = [];
        let allRoutes = [];

        for (let route of rawRoutes) {
            if (isNaN(route.routeShortName)) {
                textedRoutes.push(route)
            } else {
                numberedRoutes.push(route);
            }
        }

        numberedRoutes = numberedRoutes.sort((a, b) => {
            let numA = parseFloat(a.routeShortName);
            let numB = parseFloat(b.routeShortName);
            if (numA < numB)
                return -1;
            if (numA > numB)
                return 1;
            return 0;
        });

        textedRoutes = textedRoutes.sort((a, b) => {
            if (a.routeShortName < b.routeShortName)
                return -1;
            if (a.routeShortName > b.routeShortName)
                return 1;
            return 0;
        });

        allRoutes = allRoutes.concat(numberedRoutes).concat(textedRoutes);

        return allRoutes;
    }

    static sortNumberString(array, property) {

        let numberedItems = [];
        let textedItems = [];
        let result = [];

        for (let item of array) {
            if (isNaN(item[property])) {
                textedItems.push(item)
            } else {
                numberedItems.push(item)
            }
        }

        numberedItems = numberedItems.sort((a, b) => {
            return a[property] - b[property]
        });

        textedItems = textedItems.sort((a, b) => {
            return a[property] < b[property] ? 1 : -1
        });

        result = result.concat(numberedItems).concat(textedItems);
        return result;
    }

    static capitalizeFirstLetter(text) {
        return text.charAt(0).toUpperCase() + text.substring(1)
    }

    //Authorization event dispatch
    static canCreateEvents() {
        return true;
    }

    static canDispatchEvents() {
        return true;
    }

    static canValidateEvents() {
        return true;
    }

    static isAuthorizedAccessCom() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "allow_access_communication") {
                    return true;
                }
            }
        }
        return false;
    }

    static isAuthorizedAccessGeolite() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "allow_access_geolite") {
                    return true;
                }
            }
        }
        return false;
    }

    static isAuthorizedAccessFlexnav() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "allow_access_flexnav") {
                    return true;
                }
            }
        }
        return false;
    }

    static isAuthorizedAccessSynoptic() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "allow_access_synoptic") {
                    return true;
                }
            }
        }
        return false;
    }


    static isAuthorizedDispatch() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "allow_access_dispatch") {
                    return true;
                }
            }
        }
        return false;
    }

    static isAuthorizedUnavailability() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "allow_access_unavailability") {
                    return true;
                }
            }
        }
        return false;
    }

    static isAuthorizedFlexnav() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "allow_access_flexnav") {
                    return true;
                }
            }
        }
        return false;
    }

    static isAuthorizedMileageAllowance() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "allow_access_mileage_allowance") {
                    return true;
                }
            }
        }
        return false;
    }

    static isEventLifeCyclePlaceRequired() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "requires_event_lifecycle_place") {
                    return true;
                }
            }
        }
        return false;
    }

    static isDevServer() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "is_dev_server") {
                    return true;
                }
            }
        }
        return false;
    }

    static isServerTag(tag: string) {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (tag && parameter.param_key === "server_tag") {
                    return parameter.param_str_value.toUpperCase() === tag.toUpperCase();
                }
            }
        }
        return false;
    }

    static isRedirectToUsersAfterVehicleDispatch() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "redirect_to_user_dispatch_after_vehicle") {
                    return true;
                }
            }
        }
        return false;
    }

    static getDefaultMissionReportFormTemplateId() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "default_mission_report_form_template_id") {
                    return parameter.param_str_value;
                }
            }
        }
        return false;
    }

    static serverHasZenbusPoolId() {
        let serverParameters = global.serverParameters;
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "zenbus_pool_id") {
                    return true;
                }
            }
        }
        return false;
    }

    static hexToRgba(hex, alpha) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);

        return "rgba(" + r + "," + g + "," + b + "," + alpha + ")";
    }

    static getLatenessThresholds() {
        let serverParameters = global.serverParameters;
        let result = {
            trip: [-3, -1, 5, 15],
            shift: [0, 5],
        }
        if (serverParameters) {
            for (let parameter of serverParameters) {
                if (parameter.param_key === "lateness_very_early_threshold") {
                    result.trip[0] = parseInt(parameter.param_str_value)
                } else if (parameter.param_key === "lateness_early_threshold") {
                    result.trip[1] = parseInt(parameter.param_str_value)
                } else if (parameter.param_key === "lateness_late_threshold") {
                    result.trip[2] = parseInt(parameter.param_str_value)
                } else if (parameter.param_key === "lateness_very_late_threshold") {
                    result.trip[3] = parseInt(parameter.param_str_value)
                } else if (parameter.param_key === "shift_start_lateness_late_threshold") {
                    result.shift[0] = parseInt(parameter.param_str_value)
                } else if (parameter.param_key === "shift_start_lateness_very_late_threshold") {
                    result.shift[1] = parseInt(parameter.param_str_value)
                }
            }
        }
        return result;
    }

    static missionDisplayReversed() {
        return global.proxyCompany.tag !== "STL";
    }

    static shouldShowDispatchVehicleAfterUser() {
        return global.proxyCompany.tag === "STL";
    }

    static fitMapToBounds(mapRef, bounds) {
        if (bounds) {
            let leafletBounds = [[bounds.minLat, bounds.minLng], [bounds.maxLat, bounds.maxLng]];
            try {
                mapRef.leafletElement.fitBounds(leafletBounds)
            } catch (e) {
                console.log(e)
            }
        }
    }

    // static renderHex(letter) {
    //     for (let alphabet of color.color) {
    //         if (alphabet.letter === letter.toUpperCase()) {
    //             return alphabet.color
    //         }
    //     }
    // }

    static getContrastYIQ(hexcolor) {
        if (hexcolor.length === 7) {
            hexcolor = hexcolor.substring(1);
        }
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 140) ? '#222222' : '#FFFFFF';
    }


}


