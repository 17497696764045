import './App.css';
import React, {Component} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Calendar from "./components/Calendar";
import {EventEmitter} from 'fbemitter';

import HModal from "./components/shared/HModal/HModal";
import HSnackBar from "./components/shared/HSnackBar/HSnackBar";
import Sidebar from "./components/AppBar/sidebar";
import Home from "./components/HomePage/Home";
import GTFSAPI from "./components/HomePage/api/GTFSAPI";
import FilterRoutesIndex from "./components/FilterRoutes/FilterRoutesIndex";

export default class App extends Component {

    constructor(props) {
        super(props);

        global.emitter = new EventEmitter();
    }

    componentDidMount() {
        this.initializeHModalFunction();
        this.getAgencyName()
    }

    initializeHModalFunction() {
        global.showModal = function (args) {
            global.emitter.emit("showHModal", args);
            let responseListener: any;
            return new Promise((resolve, reject) => {
                responseListener = global.emitter.addListener("HModalResponse", (response) => {
                    responseListener.remove();
                    if (response.cancel) {
                        reject()
                    } else {
                        resolve(response.result);
                    }
                });
            });
        };

        global.hideModal = function (args) {
            global.emitter.emit("HModalAsyncTaskDone");
        }

        global.showLoader = function (args) {
            global.emitter.emit("showHLoader", args);
        };

        global.hideLoader = function (args) {
            global.emitter.emit("hideHLoader");
        };

        global.showPopover = function (args) {
            global.emitter.emit("showHPopover", args);
        };

        global.hidePopover = function (args) {
            global.emitter.emit("hideHPopover", args);
        };

    }

    async getAgencyName() {
        const agencyName = await GTFSAPI.checkIsNoGTFSInDatabase()
        if (agencyName === true) {
            global.emitter.emit("showAgencyName", 'empty');
            global.agencyName = 'empty'

        } else {
            global.emitter.emit("showAgencyName", agencyName);
            global.agencyName = agencyName
        }

    }

    render() {
        return (
            <Router>
                {/*<Home/>*/}
                <HModal/>
                <HSnackBar/>

                <div style={{width: '100vw', height: '100vh', display: 'flex', flexDirection: 'row',}}>
                    <Sidebar/>
                    {this.renderRouterContent()}
                </div>
            </Router>
        );
    }

    renderRouterContent() {
        return (
            <div style={{width: '100%', height: '100%', backgroundColor: "#1E2129"}}>
                <Switch>
                    <Route path="/home" exact={true} history={this.props.history} match={this.props.match} render={(props) => (
                        <Home {...props}/>
                    )}/>

                    <Route path="/filter/routes" exact={true} history={this.props.history} match={this.props.match} render={(props) => (
                        <FilterRoutesIndex {...props}/>
                    )}/>

                    <Route path="/calendar" exact={true} history={this.props.history} match={this.props.match} render={(props) => (
                        <Calendar {...props}/>
                    )}/>
                    <Redirect to={"/home"}/>
                </Switch>
            </div>
        )
    }

}


