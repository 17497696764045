import Tools from "../Tools/Tools";
import React, {useEffect, useState} from "react"
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";


function HTitle({title}) {

    useEffect(() => {
        console.log(global.agencyName, 'agencyName')
        const listener = global.emitter.addListener('showAgencyName', (agencyName) => {
            setAgencyName(agencyName)
        })
        return () => listener.remove()
    }, [])

    const [agencyName, setAgencyName] = useState(global.agencyName)
    const history = useHistory();

    async function handleClick() {
        await global.emitter.emit("reset")
        await history.push("/home");
    }

    return (

        <div style={{height: 60, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 30, paddingRight: 10}}>
            <p style={{margin: 0, fontSize: 24, fontWeight: 'bold', color: '#E6E6E6', flex: 1}}>{Tools.translatePathname(title)}</p>
            {agencyName && agencyName !== 'empty' && agencyName !== true &&
            <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#282B30', borderRadius: 18, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5}}>

                <div style={{
                    width: 30,
                    height: 30,
                    backgroundColor: '#A63A34',
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: 'center',
                    borderRadius: 15,
                    marginRight: 10
                }}>
                    <p style={{textTransform: "uppercase", margin: 0, color: '#E6E6E6'}}>{agencyName.charAt(0)}</p>
                </div>
                <p style={{margin: 0, color: '#E6E6E6'}}>{agencyName}</p>

            </div>
            }
            <Tooltip title={"Déconnexion"} placement={"bottom"}>

            <div onClick={() => handleClick()}
                style={{
                width: 30, height: 30, padding: 5, backgroundColor: '#282B30', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', borderRadius: '50%',
                marginLeft: 10, cursor: 'pointer'
            }}>

                <Icon style={{fontSize: 20, color: '#E6E6E6'}}>exit_to_app</Icon>
            </div>
            </Tooltip>

        </div>
    )
}

export default HTitle