import React, {Component} from 'react';
import './HSnackBar.css';
import {Icon} from "@material-ui/core";

export default class HSnackBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snacks: []
        };

        global.emitter.addListener('showSnackBar', (snackBarMessage) => {
            this.onSnackReceived(snackBarMessage);
        });
    }

    onSnackReceived(snack) {
        let snacks = this.state.snacks.slice();

        snack.timestamp = new Date().getTime();
        if (!snack.type) {
            snack.type = 'info';
        }

        snacks.push(snack);
        this.setState({
            snacks: snacks
        })
    }

    //POSSIBLE MEMORY LEAK: SNACKS LIST ARE NEVER CLEARED
    render() {
        return (
            <div style={{position: 'fixed', top: 70, right: 10, width: 400, zIndex: 3000, display: "flex", flexDirection: 'column-reverse'}}>
                {this.state.snacks.map((snack, index) => {
                    return (
                        <Snack key={'snack' + index}
                               snack={snack}
                               onSnackExpired={(snack) => {
                                   this.onSnackExpired(snack)
                               }}/>
                    )
                })}
            </div>
        )
    }
}

class Snack extends Component {

    constructor(props) {
        super(props);
        this.state = {
            snackStyles: this.getSnackStyles(),
            expired: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                expired: true
            });
        }, 3000)
    }

    getSnackStyles() {
        switch (this.props.snack.type) {
            case 'success':
                return {
                    backgroundColor: '#2cbe4e',
                    color: '#FFFFFF',
                    icon: 'check_circle'
                };
            case 'info':
                return {
                    backgroundColor: '#1079FC',
                    color: '#FFFFFF',
                    icon: 'info'
                };
            case 'error':
                return {
                    backgroundColor: '#ee3e3b',
                    color: '#FFFFFF',
                    icon: 'warning'
                };
            default:
                return {
                    backgroundColor: '#313131',
                    color: '#FFFFFF',
                    icon: 'info'
                }
        }
    }

    render() {
        if (this.state.expired === true) {
            return null;
        }
        return (
            <div style={{
                width: 'calc(100% - 8px)', backgroundColor: '#343842', borderRadius: 3, margin: 4,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)', padding: 0, marginBottom: 10, position: "relative"
            }}>

                <div style={{height: 37, width: 37, position: "absolute", top: 0, right: 0, display: "flex", justifyContent: "center", alignItems: 'center', cursor: "pointer",}}
                     onClick={() => {
                         this.setState({expired: true})
                     }}>
                    <Icon style={{fontSize: 18, color: '#EFEFEF'}}>clear</Icon>
                </div>


                <div style={{width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        {this.props.snack && this.props.snack.title &&
                        <div style={{
                            width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                            borderBottom: "1px solid rgba(255,255,255,0.3)", padding: 8, paddingLeft: 8
                        }}>

                            <div style={{
                                backgroundColor: this.state.snackStyles.backgroundColor, height: 20, width: 20, borderRadius: 4, marginRight: 8,
                                border: "1px solid #FFFFFF"
                            }}/>

                            <p style={{color: "#FFFFFF", fontWeight: 'bold', textAlign: 'left', margin: 0, flex: 1, fontSize: 15}}>
                                {this.props.snack.title}
                            </p>
                        </div>
                        }

                        {this.props.snack && this.props.snack.body &&
                        <p style={{color: "#EFEFEF", textAlign: 'left', margin: 0, fontSize: 14, padding: 8}}>
                            {this.props.snack.body}
                        </p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
