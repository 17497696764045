import React from 'react'
import HTitle from "../shared/HTitle";
import FilterRoutes from "./FilterRoutes";

export default function FilterRoutesIndex (props) {
    return (
        <div style={{width: "100%", height: "100%", backgroundColor: '#36393E'}}>
            <HTitle title={props.location.pathname}/>
            <div style={{
                display: 'flex', flexDirection: 'row', width: '100%', height: "calc(100% - 60px)"
            }}>
            <FilterRoutes />
            </div>
        </div>
    )
}