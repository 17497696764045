import React from 'react'
import ResultGTFS from "./ResultGTFS";
import HTitle from "../shared/HTitle";

function Home (props) {

    return (
        <div style={{width: "100%", height: "100%", backgroundColor: "#36393E", margin: 0, padding: 0, overflowY: "hidden"}}>
            <HTitle title={props.location.pathname} />
            <div style={{
                display: 'flex', flexDirection: 'row', width: '100%', justifyContent: "center", alignItems: 'center', height: "calc(100% - 60px)"}}>
                <ResultGTFS />
            </div>
            </div>
    )
}

export default Home;