export default class GTFSAPI {

    static validateGTFS(file): Promise<any> {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("zip_file", file);
            let url = "https://yan.gtfs.comparator.hubup.cloud/validate/gtfs";

            fetch(url, {
                method: "POST",
                body: formData
            })
                .then((response) => {
                    return response.json()
                })
                .then((result: any) => {
                    resolve(result)
                })
                .catch(error => reject(error));
        })
    }

    static checkIsNoGTFSInDatabase() {

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/gtfs/check", requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        })

    }

    static clearDatabase() {

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/gtfs/truncate", requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        })

    }

    static deleteTripsWithoutStopTimes() {

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/delete/trips/without/stop/times", requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        })

    }

    static deleteTripsWithInvalidSequenceTimes() {

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/delete/trips/with/invalid/sequence/times", requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        })

    }

    static exportGTFS() {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://yan.gtfs.comparator.hubup.cloud/export/current/gtfs", requestOptions)
            .then(res => res.arrayBuffer())
            .then(resp => {
                // set the blog type to final zip
                const file = new Blob([resp], {type: 'application/zip'});

                // process to auto download it
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `valid_gtfs.zip`;
                link.click();
            });
    }

    static validateGTFSInBase() {

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/validate/current/gtfs", requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        })

    }

    static exportFixesHistoric() {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/fixes/historic", requestOptions)
                .then(res => res.arrayBuffer())
                .then(resp => {
                    // set the blog type to final zip
                    const file = new Blob([resp], {type: 'application/zip'});

                    // process to auto download it
                    const fileURL = URL.createObjectURL(file);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = `fixes.txt`;
                    link.click();
                })
                .catch(error => console.log('error', error));
        })
    }

    static deleteRoutes(payload) {

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: JSON.stringify({routeIds: payload})
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/delete/routes/and/their/informations", requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        })

    }

}