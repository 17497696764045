export class CalendarAPI {

    static getCalendar() {
        return new Promise((resolve,reject)=>{
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/calendar/get/all", requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        })
    }

    static modifyCalendar(payload) {
        return new Promise((resolve, reject) => {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            let raw = JSON.stringify(payload)

            let requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/calendar/modify/" + payload.id, requestOptions)
                .then(response => response.text())
                .then(result => resolve(result))
                .catch(error => reject({id:12569,data:error}));
        })
    }
}