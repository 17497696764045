import React from 'react';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import HForm from "../../shared/HForm/HForm";
import moment from "moment";
import {CalendarAPI} from "../api/CalendarAPI";
import "../Calendar.css"
import '../../HomePage/HomePage.css'
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

export default function CalendarTable ({onShouldReloadCalendars, calendars}) {

        return(
            <div style={{width: '100%', height: 'calc(100%-60px)', padding: 30, backgroundColor: '#36393E', display: 'flex', justifyContent: 'center'}}>

                <div className={'wrapper-table-calendar'}
                    style={{width: '98%', minHeight: 100,  maxHeight: 900, borderRadius: 8, backgroundColor: '#202225', overflow: 'hidden', marginTop: 0}}>

                    {/*Header*/}
                <div style={{height: 60, width: '100%', backgroundColor: "#333", color: "#E6E6E6", display: 'flex', flexDirection: 'row', alignItems: 'center',
                    fontWeight: 'bold', wordBreak: "break-all",
                }}>
                    <p style={{margin: 0, width: '5%', paddingLeft: 10}}>Id</p>
                    <p style={{margin: 0, width: '5%'}}>Service Id</p>
                    <p style={{margin: 0, width: '8%'}}>Lundi</p>
                    <p style={{margin: 0, width: '8%'}}>Mardi</p>
                    <p style={{margin: 0, width: '8%'}}>Mercredi</p>
                    <p style={{margin: 0, width: '8%'}}>Jeudi</p>
                    <p style={{margin: 0, width: '8%'}}>Vendredi</p>
                    <p style={{margin: 0, width: '8%'}}>Samedi</p>
                    <p style={{margin: 0, width: '8%'}}>Dimanche</p>
                    <p style={{margin: 0, width: '20%', paddingLeft: 15}}>Date</p>
                </div>

                {/*Body*/}
                <div  className={'result'} style={{height: '92%'}}>
                    {calendars.map(calendar => {
                    return (
                        <div key={calendar.id + ""} style={{height: 50, color: "E6E6E6", display: "flex", flexDirection: 'row', alignItems: 'center'}}
                             className={'row-result-calendar'}>
                            <p style={{margin: 0, width: '5%', paddingLeft: 10, color: '#E6E6E6'}}>{calendar.id}</p>
                            <p style={{margin: 0, width: '5%', color: '#E6E6E6'}}>{calendar.service_id}</p>
                            <p style={{margin: 0, width: '8%', color: '#E6E6E6', paddingLeft: 15 }}>
                                {calendar.monday ?
                                    <Icon style={{color:"#2CBE4E", fontSize:18}}>check_circle</Icon>
                                    :  <Icon style={{color:"#EE3E3B", fontSize:18}}>cancel</Icon>
                                }</p>
                            <p style={{margin: 0, width: '8%', color: '#E6E6E6', }}>
                                {calendar.tuesday ?
                                    <Icon style={{color:"#2CBE4E", fontSize:18}}>check_circle</Icon>
                                    :  <Icon style={{color:"#EE3E3B", fontSize:18}}>cancel</Icon>
                                }</p>
                            <p style={{margin: 0, width: '8%', color: '#E6E6E6'}}>
                                {calendar.wednesday ?
                                    <Icon style={{color:"#2CBE4E", fontSize:18}}>check_circle</Icon>
                                    :  <Icon style={{color:"#EE3E3B", fontSize:18}}>cancel</Icon>
                                }</p>
                            <p style={{margin: 0, width: '8%', color: '#E6E6E6'}}>
                                {calendar.thursday ?
                                    <Icon style={{color:"#2CBE4E", fontSize:18}}>check_circle</Icon>
                                    :  <Icon style={{color:"#EE3E3B", fontSize:18}}>cancel</Icon>
                                }</p>
                            <p style={{margin: 0, width: '8%', color: '#E6E6E6'}}>{
                                calendar.friday ?
                                    <Icon style={{color:"#2CBE4E", fontSize:18}}>check_circle</Icon>
                                    :  <Icon style={{color:"#EE3E3B", fontSize:18}}>cancel</Icon>
                            }</p>
                            <p style={{margin: 0, width: '8%', color: '#E6E6E6'}}>
                                {calendar.saturday ?
                                    <Icon style={{color: "#2CBE4E", fontSize: 18}}>check_circle</Icon>
                                    : <Icon style={{color: "#EE3E3B", fontSize: 18}}>cancel</Icon>
                                }</p>
                            <p style={{margin: 0, width: '8%', color: '#E6E6E6'}}>
                                {calendar.sunday ?
                                    <Icon style={{color:"#2CBE4E", fontSize:18}}>check_circle</Icon>
                                    :  <Icon style={{color:"#EE3E3B", fontSize:18}}>cancel</Icon>
                                }
                            </p>
                            <p style={{margin: 0, width: '20%', color: '#E6E6E6', flex: 1}}>{calendar.prettyStartDate} ➔ {calendar.prettyEndDate}</p>
                            <div style={{margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Tooltip title={`Éditer`} placement={"left"}>
                                    <div className={"delete-button-container"} style={{marginRight: 5}} onClick={()=>{openModifyModal(calendar)}}>
                                        <Icon className={'delete-button'} style={{fontSize: 22, color: '#E6E6E6', marginTop: -2}}>edit</Icon>
                                    </div>
                                </Tooltip>


                            </div>
                        </div>
                    )})
                    }
                </div>
                </div>

            </div>
        )


    function openModifyModal(calendar) {
        testModal(calendar)
    }

    function testModal(calendar){

        let formFields = [
            {type: "boolean", key: "monday", defaultValue: calendar.monday === true, label: "Lundi", placeholder: "", validationRules: [""]},
            {type: "boolean", key: "tuesday", defaultValue: calendar.tuesday === true, label: "Mardi", validationRules: [""]},
            {type: "boolean", key: "wednesday", defaultValue: calendar.wednesday === true, label: "Mercredi", validationRules: [""]},
            {type: "boolean", key: "thursday", defaultValue: calendar.thursday === true, label: "Jeudi", validationRules: [""]},
            {type: "boolean", key: "friday", defaultValue: calendar.friday === true, label: "Vendredi", validationRules: [""]},
            {type: "boolean", key: "saturday", defaultValue: calendar.saturday === true, label: "Samedi", validationRules: [""]},
            {type: "boolean", key: "sunday", defaultValue: calendar.sunday === true, label: "Dimanche", validationRules: [""]},
            {type: "date", key: "start_date", defaultValue: calendar.start_date ? moment(calendar.start_date).toISOString().split('.')[0] + "Z" : moment(), label: "Date de fin", validationRules: [""]},
            {type: "date", key: "end_date", defaultValue: calendar.end_date ? moment(calendar.end_date).toISOString().split('.')[0] + "Z" : moment(), label: "Date de fin", validationRules: [""]},
        ];

        global.showModal({
            title: "Modification d'un calendrier",
            body: "",
            warning: "ATTENTION!",
            form: <HForm form={{fields: formFields}}/>,
            cancelable: false,
            cancelButtonText: "Annuler",
            okButtonText: "Continuer",
        })
            .then((data) => {

                let payload = {}
                const initialValue = formFields.map(el => el.defaultValue)
                const newValue = [
                    data.formResult.monday,
                    data.formResult.tuesday,
                    data.formResult.wednesday,
                    data.formResult.thursday,
                    data.formResult.friday,
                    data.formResult.saturday,
                    data.formResult.sunday,
                    moment(data.formResult.start_date).toISOString().split('.')[0] + "Z",
                    moment(data.formResult.end_date).toISOString().split('.')[0] + "Z",
                ]

                debugger
                for (let i = 0 ; i < initialValue.length - 1 ; i++) {
                        if (initialValue[i] !== newValue[i]) {
                            let key = formFields[i].key
                            payload[key] = newValue[i]
                        }
                }

                payload.id = calendar.id

                // let payload = {
                //     id: calendar.id,
                //     monday: data.formResult.monday,
                //     tuesday: data.formResult.tuesday,
                //     wednesday: data.formResult.wednesday,
                //     thursday: data.formResult.thursday,
                //     friday: data.formResult.friday,
                //     saturday: data.formResult.saturday,
                //     sunday: data.formResult.sunday,
                //     end_date: moment(data.formResult.end_date).toISOString().split('.')[0] + "Z",
                //     start_date: moment(data.formResult.start_date).toISOString().split('.')[0] + "Z",
                // }
                CalendarAPI.modifyCalendar(payload)
                    .then(()=>{
                        onShouldReloadCalendars();
                    })
                    .catch((err)=>{
                        console.log(err)
                    })

            })
            .catch(() => {})
    }

}