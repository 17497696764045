import React, {Component} from 'react';
import './HMultiListPicker.css';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress, TextField, Icon, IconButton, Tooltip
} from "@material-ui/core";
import VirtualList from 'react-tiny-virtual-list';
import {withTranslation} from "react-i18next";

class HMultiListPicker extends Component {

    constructor(props){
        super(props);

        this.state={
            searchText:"",
            availableItems:this.props.items,
            sortedAvailableItems:this.sortItems(this.props.items),
            // selectedItems:[],
            selectedItems:this.props.itemsAlreadySelected ? this.props.itemsAlreadySelected : [],
        };
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            items:nextProps.items,
            sortedItems:nextProps.items
        })
    }

    onAbort(){
        this.props.onAbort()
    }

    onItemsSelected(){
        this.props.onItemsSelected(this.state.selectedItems);
    }


    handleSearchTextChange(event){

        let searchText = event.target.value;

        let rawItems = this.state.availableItems.slice();
        let newSortedItems = this.filterItems(rawItems,searchText);
        newSortedItems = this.sortItems(newSortedItems);

        this.setState({
            searchText:searchText,
            sortedAvailableItems:newSortedItems
        });
    };

    filterItems(items,query){
        let rawItems = items;
        let newSortedItems = [];

        for(let item of rawItems){

            let matchFound = false;
            for(let property in item){
                if(typeof item[property] === 'string' || item[property] instanceof String){
                    if(item[property].toUpperCase().indexOf(query.toUpperCase()) !== -1){
                        matchFound = true;
                        break;
                    }
                }
            }
            if(matchFound === true){
                newSortedItems.push(item)
            }
        }

        return newSortedItems;
    }

    sortItems(raw){
        if(this.props.sortBy && this.props.items.length > 0){
            raw = raw.sort((a,b)=>{
                if(a[this.props.sortBy] < b[this.props.sortBy]){
                    return -1
                }
                else{
                    return 1;
                }
            });
        }
        return raw;
    }

    selectAll(){
        let availableItems = this.state.availableItems.slice();
        let selectedItems = this.state.selectedItems.slice();

        let selectedItemsAll = selectedItems.concat(availableItems)

        let sortedSelectedItems = this.sortItems(selectedItemsAll);

        this.setState({
            availableItems:[],
            sortedAvailableItems: [],
            selectedItems:sortedSelectedItems,
        })
    }

    selectItem(item){
        let availableItems = this.state.availableItems.slice();
        let selectedItems = this.state.selectedItems.slice();

        selectedItems.push(item);
        availableItems.splice(availableItems.indexOf(item),1);

        let sortedSelectedItems = this.sortItems(selectedItems);
        let newSortedAvailableItems = this.filterItems(availableItems,this.state.searchText);

        this.setState({
            availableItems:availableItems,
            sortedAvailableItems:newSortedAvailableItems,
            selectedItems:sortedSelectedItems,
        })
    }

    unselectItem(item){

        let availableItems = this.state.availableItems.slice();
        let selectedItems = this.state.selectedItems.slice();

        availableItems.push(item);
        let sortedAvailableItems = this.sortItems(availableItems);

        selectedItems.splice(selectedItems.indexOf(item),1);
        let newSortedAvailableItems = this.filterItems(sortedAvailableItems,this.state.searchText);

        this.setState({
            availableItems:sortedAvailableItems,
            sortedAvailableItems:newSortedAvailableItems,
            selectedItems:selectedItems,
        })
    }

    unSelectAll(){
        let availableItems = this.state.availableItems.slice();
        let selectedItems = this.state.selectedItems.slice();

        let availableItemsAll = availableItems.concat(selectedItems)

        let sortedAvailableItems = this.sortItems(availableItemsAll);
        let newSortedAvailableItems = this.filterItems(sortedAvailableItems,this.state.searchText);

        this.setState({
            availableItems:availableItemsAll,
            sortedAvailableItems: newSortedAvailableItems,
            selectedItems:[],
        })
    }

    render(){

        const {t} = this.props;

        return(
            <div style={{position:'fixed', top:0, right:0, width:0, height:0, zIndex:4000}}>
                <Dialog
                    open={true}
                    onClose={()=>{this.onAbort()}}
                    aria-labelledby="h-alert-dialog-title"
                    aria-describedby="h-alert-dialog-description"
                    maxWidth={false}
                >
                    <DialogTitle id="h-alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent style={{width:850}}>

                        <DialogContentText id="alert-dialog-description">{this.props.body}</DialogContentText>

                        {this.props.items.length === 0 && !this.props.allowEmptyList &&
                        <div style={{height:50, width:"100%", borderRadius:6, border:"1px solid #CCCCCC", display:"flex",
                            flexDirection:"row", justifyContent:"flex-start", alignItems:'center', padding:10, marginTop:10, backgroundColor:"#FAFAFA"}}>
                            <CircularProgress size={25} style={{color:'#1079FC', marginRight:10}}/>
                            <p style={{color:'#555555', textAlign:"left", margin:0}}>Chargement de la liste en cours...</p>
                        </div>
                        }

                        {this.renderCondition() &&
                            <div style={{width:"100%", marginTop:10, display:"flex", flexDirection:"row"}}>
                                <div style={{width:"calc(50% - 10px)", borderRadius:6, border:"1px solid #CCCCCC", overflow:"hidden"}}>

                                    <div style={{height:46, width:"100%", borderBottom:"1px solid #CCCCCC", display:"flex", flexDirection:"row",
                                        justifyContent:"flex-start", alignItems:'center', paddingLeft:10, paddingRight:4}}>

                                        <p style={{display:"flex",flex:1, margin:0, paddingRight:10, textAlign:"left", color:'#555555',
                                            fontWeight:"normal", fontSize:14}}>
                                            {"Disponibles"}
                                        </p>

                                        <TextField
                                            className={"search-outlined"}
                                            placeholder={"Rechercher"}
                                            style={{height:38, padding:0, width:240}}
                                            disabled={this.props.items.length === 0}
                                            InputProps={{
                                                style: {
                                                    height:38,
                                                    color:'#222222'
                                                }
                                            }}
                                            value={this.state.searchText}
                                            onChange={(event)=>{this.handleSearchTextChange(event)}}
                                            margin="none"
                                            variant="outlined"
                                        />


                                    </div>
                                    <div style={{width:"100%", height:340, overflowY:"auto", backgroundColor:'#FAFAFA'}}>

                                        <VirtualList
                                            width='100%'
                                            height={340}
                                            itemCount={this.state.sortedAvailableItems.length}
                                            itemSize={40}
                                            overscanCount={40}
                                            renderItem={({index, style}) =>{

                                                let item = this.state.sortedAvailableItems[index];

                                                return(
                                                    <div key={index} style={style}>
                                                        <div style={{width:"100%", height:40, display:"flex", justifyContent:"flex-start",
                                                            alignItems:"center", paddingLeft:10, paddingRight:0}} className={"selectable-item"} key={"item"+index}
                                                             onClick={()=>{this.selectItem(item)}}>

                                                            <div style={{width:"100%", height:"100%", overflow:"hidden", display:"flex", justifyContent:"flex-start",
                                                            alignItems:'center'}}>
                                                                <p style={{margin:0, width:"calc(100% - 40px)", textAlign:"left", color:'#222222', fontSize:14}}>
                                                                    {this.props.renderItemKeys.map((key,index)=>{
                                                                        return(
                                                                            <span style={{marginRight:10}} key={index + "k"}>{item[key]}</span>
                                                                        )
                                                                    })}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}>
                                        </VirtualList>

                                    </div>

                                </div>

                                <div style={{height:340, width:25, display:"flex", justifyContent:"center", alignItems:'center', flexDirection:"column"}}>

                                    <Tooltip title={"Sélectionner l'ensemble des éléments"} placement={"right"}>
                                    <IconButton style={{padding:4, marginTop:30}} onClick={()=>{this.selectAll()}}>
                                        <Icon style={{color:'#797979', fontSize:20, }}>
                                            {"arrow_forward"}
                                        </Icon>
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Déselectionner l'ensemble des éléments"} placement={"left"}>
                                    <IconButton style={{padding:4}} onClick={()=>{this.unSelectAll()}}>
                                        <Icon style={{color:'#797979', fontSize:22}}>
                                            {"arrow_backward"}
                                        </Icon>
                                    </IconButton>
                                    </Tooltip>
                                </div>

                                <div style={{width:"calc(50% - 10px)", borderRadius:6, border:"1px solid #CCCCCC", overflow:"hidden"}}>

                                    <div style={{height:46, width:"100%", borderBottom:"1px solid #CCCCCC", display:"flex", flexDirection:"row",
                                        justifyContent:"flex-start", alignItems:'center', paddingLeft:10, paddingRight:4}}>

                                        <p style={{display:"flex",flex:1, margin:0, paddingRight:10, textAlign:"left", color:'#555555',
                                            fontWeight:"normal", fontSize:14}}>
                                            {"Sélectionnés (" + this.state.selectedItems.length + ")"}
                                        </p>

                                    </div>
                                    <div style={{width:"100%", height:340, overflowY:"auto", backgroundColor:'#FAFAFA'}}>

                                        <VirtualList
                                            width='100%'
                                            height={340}
                                            itemCount={this.state.selectedItems.length}
                                            itemSize={40}
                                            overscanCount={40}
                                            renderItem={({index, style}) =>{

                                                let item = this.state.selectedItems[index];

                                                return(
                                                    <div key={index} style={style}>
                                                        <div style={{width:"100%", height:40, display:"flex", justifyContent:"flex-start",
                                                            alignItems:"center", paddingLeft:10, paddingRight:0}} className={"selectable-item"} key={"item"+index}
                                                             onClick={()=>{this.unselectItem(item)}}>

                                                            <div style={{width:"100%", height:"100%", overflow:"hidden", display:"flex", justifyContent:"flex-start",
                                                                alignItems:'center'}}>
                                                                <p style={{margin:0, width:"calc(100% - 40px)", textAlign:"left", color:'#222222', fontSize:14}}>
                                                                    {this.props.renderItemKeys.map((key,index)=>{
                                                                        return(
                                                                            <span style={{marginRight:10}} key={index + "k"}>{item[key]}</span>
                                                                        )
                                                                    })}
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            }}>
                                        </VirtualList>

                                    </div>

                                </div>
                            </div>
                        }

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={()=>{this.onAbort()}} color="primary">
                            {t("h_components_h_multi_list_picker_cancel_button_title")}
                        </Button>
                        <Button onClick={()=>{this.onItemsSelected()}}
                                color="primary" disabled={!this.renderCondition()}>
                            <span>
                                {t("h_components_h_multi_list_picker_ok_button_title")}
                            </span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    renderCondition () {
        if (this.props.allowEmptyList) {
            return true
        } else {
           return this.props.items.length > 0
            }}



}

export default withTranslation()(HMultiListPicker)
