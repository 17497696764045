import React, {useEffect, useState} from 'react'
import FilterRoutesAPI from "./api/FilterRoutesAPI";
import RouteCard from "./Components/RouteCard";
import './FilterRoutes.css'
import '../HomePage/HomePage.css'
import GTFSAPI from "../HomePage/api/GTFSAPI";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

export default function FilterRoutes() {

    const [routes, setRoutes] = useState([])
    const [routesSelected, setRoutesSelected] = useState([])

    useEffect(() => {
        getRoutes()
    }, [])

    async function getRoutes() {
        try {
            const data = await FilterRoutesAPI.getRoutes()
            console.log(data, 'data')
            setRoutes(data)
        } catch (error) {
            console.warn(error)
        }
    }

    function handleRoutes(routesClicked) {
        const copyData = routesSelected.slice()
        const data = copyData.map(route => route.id)
        const index = data.indexOf(routesClicked.id)
        if (index === -1) {
            setRoutesSelected([...routesSelected, routesClicked])
        } else {
            const a = copyData.filter(route => route.id !==  routesClicked.id)
            setRoutesSelected(a)

        }
    }

    async function deleteRoutes() {
        const data = routes.slice()
        const notSelected = data.filter(route => routesSelected.map(route => route.id).indexOf(route.id) === -1)
        const idRouteNotSelected = notSelected.map(route => route.route_id)
        try {
            await GTFSAPI.deleteRoutes(idRouteNotSelected)
            setRoutesSelected([])
            await getRoutes()
        } catch (e) {
            console.warn(e)
        }
    }

    return (
        <div style={{width: '100%', height: 'calc(100%-60px)', display: 'flex', flexDirection: 'row', backgroundColor: '#36393E', justifyContent: 'space-between',
            paddingTop:30, paddingBottom: 30, paddingLeft: 100, paddingRight: 100}}>

                {/*LeftScreen*/}
                <div style={{display: 'flex', flexDirection: 'column', width: 400}}>
                    <div style={{marginLeft: 50, width: 400}}>
                        <p style={{fontSize: 30, margin: 0, color: '#E6E6E6', fontWeight: 'bold'}}>Nombre de lignes {routes.filter(r => routesSelected.map(route => route.id).indexOf(r.id) === -1).length}</p>
                        <p style={{color: "#FFFFFF"}}>Sélectionner les routes que vous souhaitez conserver. Les routes non sélectionnées seront supprimés</p>
                    </div>

                    <div style={{width: 400, minHeight: 50,  maxHeight: 700, marginLeft: 50, borderRadius: 8, backgroundColor: '#282B30', overflow: 'hidden', marginTop: 30}}
                         className={'wrapper-shadow'}>
                        <div className={'result'} style={{height: '100%'}}>
                            {routes.filter(r => routesSelected.map(route => route.id).indexOf(r.id) === -1).map((route, index) => {
                                return (
                                    <RouteCard
                                        key={route.id}
                                        route={route}
                                        routesSelected={routesSelected}
                                        index={index}
                                        handleRoutes={(routeClicked) => handleRoutes(routeClicked)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <Button variant={"contained"} style={{ marginLeft: 50,
                        backgroundColor: "#5C6FB5", color: '#FFFFFF', marginRight: 0, width: 150, marginTop: 20, opacity: routesSelected.length > 0 ? 1 : 0.3
                    }} size={"small"}
                            onClick={() => deleteRoutes()}>
                        {"Supprimer"}
                        <Icon style={{color: '#FFFFFF', marginLeft: 8, fontSize: 18}}>delete</Icon>
                    </Button>
                </div>

                {/*Right screen*/}
                <div style={{display: 'flex', flexDirection: 'column', width: 400}}>
                    <p style={{fontSize: 30, margin: 0, color: '#E6E6E6', fontWeight: 'bold', opacity: routesSelected.length > 0 ? 1 : 0.3}}>
                        Lignes conservées {routesSelected.length}
                    </p>
                    <p style={{color: "#FFFFFF", opacity: 0}}>Sélectionner les routes que vous souhaitez conserver. Les routes non sélectionnées seront supprimés</p>

                    {routesSelected.length > 0 &&
                        <div style={{width: 400, borderRadius: 8, backgroundColor: '#282B30', overflow: 'hidden', marginTop: 30}}
                             className={'wrapper-shadow'}>
                            <div className={'result'} style={{height: '100%'}}>
                                {routesSelected.map((route, index) => {
                                    return (
                                        <RouteCard
                                            key={route.id}
                                            route={route}
                                            routesSelected={routesSelected}
                                            index={index}
                                            handleRoutes={(routesSelectedId) => handleRoutes(routesSelectedId)}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
        </div>
    )
}