import React from "react"
import Icon from "@material-ui/core/Icon";
import {Helpers} from "../Helpers";
import Tooltip from "@material-ui/core/Tooltip";

export default function RenderRowResult({item, data, index, deleteErrors, selectedRow}) {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}} className={"renderError"}>
            <div
                style={{
                    display: 'flex', flexDirection: 'row', width: "calc(100%-2px)", alignItems: 'center', borderTop: "1px solid #636363", height: 70,
                    borderBottom: data.length - 1 === index && "1px solid #636363",
                }}>

                {/*Icon*/}
                {Helpers.renderIcon(item[0])}

                <div style={{
                    display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, width: '100%'
                }}>
                    <p style={{color: Helpers.renderColor(item[0]), margin: 0, fontSize: 20, paddingLeft: 0}}>{item[0]} ({item[1].length})</p>
                    <p style={{color: "#E6E6E6", margin: 0, fontSize: 14, paddingLeft: 0,}}>{Helpers.translateErrors(item[0])}</p>
                </div>

                {Helpers.renderError(item[0]) &&
                <Tooltip title={`Supprimer ${item[0]}`} placement={"left"}>
                    <div className={"delete-button-container"} style={{marginRight: 5}} onClick={() => deleteErrors(item[0])}>
                        <Icon className={'delete-button'} style={{fontSize: 22, color: '#E6E6E6', marginTop: -2}}>delete</Icon>
                    </div>
                </Tooltip>
                }

            </div>
            <div style={{
                display: selectedRow.indexOf(item[0]) === -1 ? "none" : "block", transition: "all 0.3s cubic-bezier(.25, .8, .25, 1)",
            }}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: 50}}>
                    {item[1] && Object.keys(item[1][0]).map((e, index) => {
                        return (
                            <div
                                key={`numéro :${e}`}
                                style={{
                                    color: "#AF3a34",
                                    backgroundColor: "#202225",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: `calc(100% / ${Object.keys(item[1][0]).length}`,
                                    borderRight: index !== Object.keys(item[1][0]).length - 1 && "1px solid #636363",
                                    borderBottom: "1px solid #636363"
                                }}>
                                <p style={{margin: 0}}>{e}</p>
                            </div>
                        )
                    })}
                </div>
                {item[1] && item[1].map((e, index) => {
                    return (
                        <div key={`ìtems: ${index}`} className={'row-map-answer'}
                             style={{display: 'flex', flexDirection: "row", width: '100%', alignItems: "center", justifyContent: "center",}}>
                            {Object.values(e).map((r, index) => {
                                return (
                                    <p key={`it: ${index}`}
                                       style={{width: `calc(100% / ${Object.keys(item[1][0]).length}`, textAlign: 'center'}}>
                                        {r}
                                    </p>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}