export default class FilterRoutesAPI {

    static getRoutes () {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch("https://yan.gtfs.comparator.hubup.cloud/get/route/informations", requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
        })
    }
}