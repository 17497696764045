import React, {Component} from 'react';
import "./HForm.css";
import {Icon, TextField, FormControl, InputLabel, Select, OutlinedInput, MenuItem, FormHelperText, Chip} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {DatePicker, TimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import moment from "moment"
import HListPicker from "../HlistPicker/HListPicker";
import i18n from "i18next"
import PropTypes from "prop-types";
import HMultiListPicker from "../HMultiListPicker/HMultiListPicker";

/**
 * This component generates a fully customizable and validatable form from an array of fields. This form can be used as-is, or
 * can be integrated in the HModal component for a better integration
 *
 */
class HForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dynamicForm:[],
        };
    }

    static propTypes = {
        /** The array of formFields of the form */
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                /** Options are [string | number | boolean | color | date | time | select | entity_select]*/
                type:PropTypes.oneOf(["string","number","boolean","color","date","time","select","entity_select"]).isRequired,
                /** The unique key of the form field*/
                key:PropTypes.string.isRequired,
                /** Default value of the field, must be relevant to the type*/
                defaultValue:PropTypes.string.isRequired,
                /** The label displayed above the field */
                label:PropTypes.string.isRequired,
                /** The placeholder of the field. Not relevant for boolean type*/
                placeholder:PropTypes.string,
                /** Determines if the text field is multiline. Can only be applied for string types*/
                multiline:PropTypes.bool,
                /** Array of validation rules for this field see the details to learn more about validation rules*/
                validationRules:PropTypes.oneOf([]),
                /** Array of {value,label} objects. Can only be applied for select type*/
                options:PropTypes.arrayOf(
                    PropTypes.shape({
                        value:PropTypes.string,
                        label:PropTypes.string,
                    })
                )
            })
        ),
    }

    _requestHFormValidationSubscription:any;

    componentDidMount(){
        this.generateDynamicForm(this.props.form);
        this._requestHFormValidationSubscription = global.emitter.addListener("requestHFormValidation",()=>{
            this.tryValidateHForm()
        });
    }

    componentWillUnmount(){
        if(this._requestHFormValidationSubscription){this._requestHFormValidationSubscription.remove()}
    }

    generateDynamicForm(form){
        let df = [];
        let id = 1;
        for(let field of form.fields){
            let dField = field;
            dField.value = field.defaultValue;
            dField.error = null;
            dField.id = id;
            dField.validationRules = this.computeValidationRules(dField.validationRules);

            df.push(dField);
            id ++;
        }
        this.setState({
            dynamicForm:df
        },()=>{
            this.tryFocusFirstField()
        })
    }

    tryFocusFirstField(){
        if(this.state.dynamicForm.length > 0){
            let firstField = this.state.dynamicForm[0];
            if(firstField.type === "string" || firstField.type === "number"){
                setTimeout(()=>{
                    try{
                        let elt = document.getElementById("field_" + firstField.id);
                        elt.focus();
                    }
                    catch(err){console.log(err)}
                },100)
            }
        }
    }

    onFieldKeyPress(event){
        if (event.key === 'Enter') {
            if(this.state.dynamicForm.length === 1){
                global.emitter.emit("programmaticallyTryHModalFinishButton");
            }
        }
    }

    getEntitySelectAvailableData(field){
        let allData = this.props.form.data;
        if(allData[field.data]){
            return allData[field.data];
        }
        return [];
    }

    handleFieldValueChange(event,field){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.value = event.target.value;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    handleDateTimeFieldValueChange(date,field){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.value = date;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    handleSelectModeChange(event,field){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.value = event.target.value;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    getDateFieldMinDate(field){
        for(let vr of field.validationRules){
            if(vr.name === "min_date_today"){
                return moment();
            }
            if(vr.name === "min_date_tomorrow"){
                return moment().add(1,"day");
            }
        }
        return moment().subtract(5,"years");
    }

    getDateFieldMaxDate(field){
        for(let vr of field.validationRules){
            if(vr.name === "max_date_today"){
                return moment();
            }
            if(vr.name === "max_date_yesterday"){
                return moment().subtract(1,"day");
            }
        }
        return moment().add(5,"years");
    }

    toggleBooleanFieldValue(field){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.value = !f.value;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    tryValidateHForm(){
       let form = this.state.dynamicForm.slice();
       let formErrorsCount = 0;
       for(let field of form){
           field = this.validateField(field);
           if(field.error){formErrorsCount++}
       }
       this.setState({
           dynamicForm:form
       });
       if(formErrorsCount === 0){

           let arrayForm = form.map((item)=>{return{key:item.key,value:item.value}});
           let objectForm = {};
           for(let item of arrayForm){
               objectForm[item.key] = item.value
           }

           global.emitter.emit("requestHFormValidationResponse",{
               valid:true,
               form:objectForm,
           })
       }
       else{
           global.emitter.emit("requestHFormValidationResponse",{
               valid:false,
           })
       }
    }

    validateField(field):any{
        let result = field;
        let error = null;

        if(field.type === "string"){error = this.validateStringField(field)}
        if(field.type === "number"){error = this.validateNumberField(field)}
        if(field.type === "boolean"){error = this.validateBooleanField(field)}
        if(field.type === "date"){error = this.validateDateField(field)}
        if(field.type === "time"){error = this.validateTimeField(field)}
        if(field.type === "select"){error = this.validateSelectField(field)}
        if(field.type === "multi_select"){error = this.validateMultiSelectField(field)}
        if(field.type === "entity_select"){error = this.validateEntitySelectField(field)}
        if(field.type === "color"){error = this.validateColorField(field)}

        result.error = error;
        return result;
    }

    validateStringField(field){

        let error = null;
        if(field.value && !field.value instanceof String){
            error = i18n.t("h_components_h_form_error_type_string_required")
        }

        let stringValidationRules = ["required","min_length","max_length"];
        let relevantValidationRules = [];
        for(let vr of field.validationRules){
            if(stringValidationRules.indexOf(vr.name) !== -1){
                relevantValidationRules.push(vr)
            }
        }

        for(let validationRule of relevantValidationRules){
            //If one error is detected, stop validating field: it already is invalid!
            if(error !== null){break;}
            switch(validationRule.name){
                case "required":
                    error = this.validateRequired(field);
                    break;
                case "min_length":
                    error = this.validateMinLength(field,validationRule.data);
                    break;
                case "max_length":
                    error = this.validateMaxLength(field,validationRule.data);
                    break;
                default:
                    break;
            }
        }
        return error;
    }

    validateNumberField(field){
        let error = null;
        if(field.value && isNaN(field.value)){
            error = i18n.t("h_components_h_form_error_type_number_required")
        }

        let stringValidationRules = ["required","integer","positive","positive_strict","negative","negative_strict",];
        let relevantValidationRules = [];
        for(let vr of field.validationRules){
            if(stringValidationRules.indexOf(vr.name) !== -1){
                relevantValidationRules.push(vr)
            }
        }

        for(let validationRule of relevantValidationRules){
            //If one error is detected, stop validating field: it already is invalid!
            if(error !== null){break;}
            switch(validationRule.name){
                case "required":
                    error = this.validateRequired(field);
                    break;
                case "integer":
                    error = this.validateInteger(field);
                    break;
                case "positive":
                    error = this.validatePositive(field);
                    break;
                case "positive_strict":
                    error = this.validatePositiveStrict(field);
                    break;
                case "negative":
                    error = this.validateNegative(field);
                    break;
                case "negative_strict":
                    error = this.validateNegativeStrict(field);
                    break;
                default:
                    break;
            }
        }
        return error;
    }

    validateBooleanField(field){
        let error = null;
        return error;
    }

    validateDateField(field){
        let error = null;

        let stringValidationRules = ["required","min_date_today","min_date_tomorrow","max_date_today","max_date_yesterday"];
        let relevantValidationRules = [];
        for(let vr of field.validationRules){
            if(stringValidationRules.indexOf(vr.name) !== -1){
                relevantValidationRules.push(vr)
            }
        }
        for(let validationRule of relevantValidationRules){
            //If one error is detected, stop validating field: it already is invalid!
            if(error !== null){break;}
            switch(validationRule.name){
                case "required":
                    error = this.validateRequired(field);
                    break;
                default:
                    break;
            }
        }
        return error;
    }

    validateTimeField(field){
        let error = null;

        let stringValidationRules = ["required"];
        let relevantValidationRules = [];
        for(let vr of field.validationRules){
            if(stringValidationRules.indexOf(vr.name) !== -1){
                relevantValidationRules.push(vr)
            }
        }
        for(let validationRule of relevantValidationRules){
            //If one error is detected, stop validating field: it already is invalid!
            if(error !== null){break;}
            switch(validationRule.name){
                case "required":
                    error = this.validateRequired(field);
                    break;
                default:
                    break;
            }
        }
        return error;
    }

    validateSelectField(field){
        let error = null;

        let stringValidationRules = ["required"];
        let relevantValidationRules = [];
        for(let vr of field.validationRules){
            if(stringValidationRules.indexOf(vr.name) !== -1){
                relevantValidationRules.push(vr)
            }
        }
        for(let validationRule of relevantValidationRules){
            //If one error is detected, stop validating field: it already is invalid!
            if(error !== null){break;}
            switch(validationRule.name){
                case "required":
                    error = this.validateRequired(field);
                    break;
                default:
                    break;
            }
        }
        return error;
    }

    validateMultiSelectField(field){
        let error = null;

        let stringValidationRules = ["min_count","max_count"];
        let relevantValidationRules = [];
        for(let vr of field.validationRules){
            if(stringValidationRules.indexOf(vr.name) !== -1){
                relevantValidationRules.push(vr)
            }
        }
        for(let validationRule of relevantValidationRules){
            //If one error is detected, stop validating field: it already is invalid!
            if(error !== null){break;}
            switch(validationRule.name){
                case "min_count":
                    error = this.validateMinCount(field,validationRule.data);
                    break;
                case "max_count":
                    error = this.validateMaxCount(field,validationRule.data);
                    break;
                default:
                    break;
            }
        }
        return error;
    }

    validateEntitySelectField(field){
        let error = null;

        let stringValidationRules = ["required"];
        let relevantValidationRules = [];
        for(let vr of field.validationRules){
            if(stringValidationRules.indexOf(vr.name) !== -1){
                relevantValidationRules.push(vr)
            }
        }
        for(let validationRule of relevantValidationRules){
            //If one error is detected, stop validating field: it already is invalid!
            if(error !== null){break;}
            switch(validationRule.name){
                case "required":
                    error = this.validateRequired(field);
                    break;
                default:
                    break;
            }
        }
        return error;
    }

    validateColorField(field){
        let error = null;

        let stringValidationRules = ["required"];
        let relevantValidationRules = [];
        for(let vr of field.validationRules){
            if(stringValidationRules.indexOf(vr.name) !== -1){
                relevantValidationRules.push(vr)
            }
        }
        for(let validationRule of relevantValidationRules){
            //If one error is detected, stop validating field: it already is invalid!
            if(error !== null){break;}
            switch(validationRule.name){
                case "required":
                    error = this.validateRequired(field);
                    break;
                default:
                    break;
            }
        }
        return error;
    }


    validateRequired(field){
        let error = null;
        if(field.value === null || (field.type === "string" && field.value.length === 0) || (field.type === "select" && field.value.length === 0)
            || (field.type === "entity_select" && field.value.length === 0)){
            error = i18n.t("h_components_h_form_error_required");
        }
        return error;
    }

    validateMinLength(field,value){
        let error = null;
        if(field.value.length > 0 && field.value.length < value){
            error = i18n.t("h_components_h_form_error_min_length_affix") + value + i18n.t("h_components_h_form_error_min_length_suffix");
        }
        return error;
    }

    validateMaxLength(field,value){
        let error = null;
        if(field.value.length > 0 && field.value.length > value){
            error = i18n.t("h_components_h_form_error_max_length_affix") + value + i18n.t("h_components_h_form_error_max_length_suffix");
        }
        return error;
    }

    validateMinCount(field,value){
        let error = null;
        if(field.value.length > 0 && field.value.length < value){
            error = "La sélection doit comporter au moins " +  value + " éléments";
        }
        return error;
    }

    validateMaxCount(field,value){
        let error = null;
        if(field.value.length > 0 && field.value.length > value){
            error = "La sélection doit comporter au maximum " +  value + " éléments";
        }
        return error;
    }

    validateInteger(field){
        let error = null;
        if(field.value && Math.floor(field.value) !== parseFloat(field.value)){
            error = i18n.t("h_components_h_form_error_type_integer_required");
        }
        return error;
    }

    validatePositive(field){
        let error = null;
        if(field.value < 0){
            error = i18n.t("h_components_h_form_error_positive");
        }
        return error;
    }

    validatePositiveStrict(field){
        let error = null;
        if(field.value <= 0){
            error = i18n.t("h_components_h_form_error_positive_strict")
        }
        return error;
    }

    validateNegative(field){
        let error = null;
        if(field.value > 0){
            error = i18n.t("h_components_h_form_error_negative");
        }
        return error;
    }

    validateNegativeStrict(field){
        let error = null;
        if(field.value >= 0){
            error = i18n.t("h_components_h_form_error_negative_strict");
        }
        return error;
    }


    render(){
        return(
            <div style={{width:"100%"}}>
                {this.state.dynamicForm.map((field)=>{
                    return(
                        <div style={{width:"100%"}} key={"h_form_field_" + field.id}>
                            {this.renderHFormField(field)}
                        </div>
                    )
                })}
            </div>
        )
    }

    renderHFormField(field){
        switch(field.type){
            case "string":
                return this.renderStringField(field);
            case "number":
                return this.renderNumberField(field);
            case "boolean":
                return this.renderBooleanField(field);
            case "date":
                return this.renderDateField(field);
            case "time":
                return this.renderTimeField(field);
            case "select":
                return this.renderSelectField(field);
            case "multi_select":
                return this.renderMultiSelectField(field);
            case "entity_select":
                return this.renderEntitySelectField(field);
            case "color":
                return this.renderColorField(field);
            default:
                return null;
        }
    }

    renderStringField(field){
        return(
            <TextField
                id={"field_" + field.id}
                type={"text"}
                value={field.value}
                required={field.validationRules.map((item)=>{return item.name}).indexOf("required") !== -1}
                label={field.label}
                placeholder={field.placeholder}
                style={{width:"100%", marginBottom:15}}
                onChange={(event)=>{this.handleFieldValueChange(event,field)}}
                onKeyPress={(event)=>{this.onFieldKeyPress(event)}}
                margin="none"
                variant="outlined"
                multiline={field.multiline === true}
                rowsMax={4}
                error={field.error !== null}
                helperText={field.error ? field.error : null}
            />
        )
    }

    renderNumberField(field){
        return(
            <TextField
                id={"field_" + field.id}
                type={"number"}
                value={field.value}
                required={field.validationRules.map((item)=>{return item.name}).indexOf("required") !== -1}
                label={field.label}
                placeholder={field.placeholder}
                style={{width:"100%", marginBottom:15}}
                onChange={(event)=>{this.handleFieldValueChange(event,field)}}
                onKeyPress={(event)=>{this.onFieldKeyPress(event)}}
                margin="none"
                variant="outlined"
                error={field.error !== null}
                helperText={field.error ? field.error : null}
            />
        )
    }

    renderDateField(field){
        return(
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <FormControl style={{width:"100%", marginBottom:15}}>
                    <DatePicker
                        emptyLabel={field.placeholder}
                        placeholder={field.placeholder}
                        value={field.value}
                        label={field.label}
                        cancelLabel={i18n.t("h_components_h_form_cancel_button_title")}
                        okLabel={i18n.t("h_components_h_form_validate_button_title")}
                        required={field.validationRules.map((item)=>{return item.name}).indexOf("required") !== -1}
                        onChange={(date) => {this.handleDateTimeFieldValueChange(date,field)}}
                        format={"dddd DD MMMM YYYY"}
                        todayLabel={i18n.t("h_components_h_form_today_label")}
                        variant={'outlined'}
                        minDate={this.getDateFieldMinDate(field)}
                        maxDate={this.getDateFieldMaxDate(field)}
                        error={field.error !== null}
                        helperText={field.error ? field.error : null}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>
        )
    }

    renderTimeField(field){
        return(
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <FormControl style={{width:"100%", marginBottom:15}}>
                    <TimePicker
                        ampm={false}
                        emptyLabel={field.placeholder}
                        value={field.value}
                        label={field.label}
                        required={field.validationRules.map((item)=>{return item.name}).indexOf("required") !== -1}
                        cancelLabel={i18n.t("h_components_h_form_cancel_button_title")}
                        okLabel={i18n.t("h_components_h_form_validate_button_title")}
                        onChange={(date) => {this.handleDateTimeFieldValueChange(date,field)}}
                        variant={'outlined'}
                        error={field.error !== null}
                        helperText={field.error ? field.error : null}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>
        )
    }

    renderBooleanField(field){
        return(
            <div style={{width:"100%", height:56, marginBottom:16, borderRadius:4}} className={"fake-field"}>
                <div style={{height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center', cursor:"pointer", paddingLeft:12}}
                onClick={()=>{this.toggleBooleanFieldValue(field)}}>
                    <Icon style={{color:field.value === true ? '#1079FC' : "#CCCCCC", marginRight:8}}>{field.value === true ? "check_box" : "check_box_outline_blank"}</Icon>
                    <p style={{fontSize:16, color:'#222222', flex:1, margin:0, textAlign:"left"}}>{field.label}</p>
                </div>
            </div>
        )
    }

    renderSelectField(field){
        let required = field.validationRules.map((item)=>{return item.name}).indexOf("required") !== -1;
        let updatedLabel = "";
        if(field.label){
            updatedLabel = field.label;
            if(required){
                updatedLabel = updatedLabel + " *"
            }
        }
        return(
            <FormControl variant="outlined" style={{width:"100%", marginBottom:15}}>
                <InputLabel style={{color:field.error ? "#F34538" : "#8E7E86"}}>{updatedLabel}</InputLabel>
                <Select
                    value={field.value}
                    style={{textAlign:"left"}}
                    onChange={(event)=>{this.handleSelectModeChange(event,field)}}
                    input={<OutlinedInput labelWidth={updatedLabel ? 6.5*updatedLabel.length : ""} name="search_mode" id="h-select-field"/>}
                    error={field.error !== null}
                >
                    {field.options.map((option,index)=>{
                        return(
                            <MenuItem key={"opt_" + index} value={option.value}>{option.label}</MenuItem>
                        )
                    })}
                </Select>
                {field.error &&<FormHelperText style={{color:'#F34538'}}>{field.error}</FormHelperText>}

            </FormControl>
        )
    }

    renderMultiSelectField(field){
        let required = field.validationRules.map((item)=>{return item.name}).indexOf("required") !== -1;
        let updatedLabel = "";
        if(field.label){
            updatedLabel = field.label;
            if(required){
                updatedLabel = updatedLabel + " *"
            }
        }
        return(
            <>
                <div style={{width:"100%", marginBottom:16, borderRadius:4, position:"relative", cursor:"pointer"}} className={"fake-field"}
                onClick={()=>{this.showMultiSelectItemsPicker(field)}}>
                    <span style={{position:"absolute", left:8, top:-9, color:"rgba(0, 0, 0, 0.77)", fontSize:11.5, backgroundColor:"#FFFFFF", padding:2,
                    paddingLeft:6, paddingRight:6}}>{updatedLabel}</span>
                    {field.value.length === 0 &&
                        <div style={{width:"100%", height:56, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:'center',
                            paddingLeft:14}}>
                            <span style={{color:"#777777", fontSize:16, width:"100%", textAlign:"left"}}>{field.placeholder}</span>
                        </div>
                    }

                    {field.value.length > 0 &&
                        <div style={{width:"100%", minHeight:56, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                        maxHeight:200, overflowY:"auto", flexWrap:"wrap", padding:15, paddingBottom:5}}>

                            {field.value.map((item)=>{
                                return(
                                    <Chip style={{marginRight: 4, marginBottom: 5}} size={"small"} label={item.id} key={"field_" + item.id}/>
                                )
                            })}

                        </div>
                    }


                </div>
                {field.multiPickerOpened === true &&
                    <HMultiListPicker
                        title={"Sélectionner des éléments"}
                        body={"Sélectionnez des éléments depuis la liste de gauche, puis cliquez sur CONFIRMER pour terminer la sélection"}
                        items={this.getMultiSelectAvailableItems(field)}
                        itemsAlreadySelected={field.value}
                        renderItemKeys={["id"]}
                        allowEmptyList={true}
                        sortBy={"id"}
                        onAbort={() => {this.hideMultiSelectItemsPicker(field)}}
                        onItemsSelected={(items) =>{this.onItemsSelected(field,items)}}
                    />
                }

            </>
        )
    }

    getMultiSelectAvailableItems(field){
        return field.options.filter((i)=>{return field.value.map((si)=>{return si.id}).indexOf(i.id) === -1});
    }

    showMultiSelectItemsPicker(field){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.multiPickerOpened = true;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    hideMultiSelectItemsPicker(field){
        let fields = this.state.dynamicForm.slice();
        for(let i = 0; i < fields.length; i++){
            let f = fields[i];
            if(f.id === field.id){
                fields[i].multiPickerOpened = false;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    onItemsSelected(field,items){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.multiPickerOpened = false;
                f.value = items;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    renderEntitySelectField(field){

        let items = this.getEntitySelectAvailableData(field);

        let required = field.validationRules.map((item)=>{return item.name}).indexOf("required") !== -1;
        let updatedLabel = "";
        if(field.label){
            updatedLabel = field.label;
            if(required){
                updatedLabel = updatedLabel + " *"
            }
        }

        return(
            <div style={{width:"100%", height:56, marginBottom:16, borderRadius:4, position:"relative", borderColor:field.error ? "#F34538" : "auto"}} className={"fake-field"}>
                <p style={{backgroundColor:'#FFFFFF', paddingLeft:4, paddingRight:4, fontSize:12, color:field.error ? "#F34538" : '#767676', position:"absolute",
                    left:10, top:-19}}>{updatedLabel}</p>
                <div style={{height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center', cursor:"pointer", paddingLeft:12,
               }} onClick={()=>{this.showFieldPicker(field)}}>
                    {field.value &&
                        <p style={{margin:0, width:"100%", textAlign:"left", color:'#222222', fontSize:16}}>
                            {field.pickerProps.renderItemKeys.map((key,index)=>{
                                return(
                                    <span style={{marginRight:6}} key={index + "k"}>{field.value[key]}</span>
                                )
                            })}
                        </p>
                    }
                    {!field.value &&
                    <p style={{fontSize:16, color:'#767676', flex:1, margin:0, textAlign:"left"}}>{field.placeholder}</p>
                    }

                </div>

                {field.pickerOpened === true &&
                    <HListPicker title={field.pickerProps.title}
                                 body={field.pickerProps.body}
                                 items={items}
                                 renderItemKeys={field.pickerProps.renderItemKeys}
                                 sortBy={field.pickerProps.sortBy}
                                 onAbort={()=>{this.hideFieldPicker(field)}}
                                 onItemSelected={(item)=>{this.onItemSelected(field,item)}}
                    />
                }
                {field.error &&<FormHelperText style={{color:'#F34538', marginLeft:12}}>{field.error}</FormHelperText>}
            </div>
        )
    }

    showFieldPicker(field){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.pickerOpened = true;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    hideFieldPicker(field){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.pickerOpened = false;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }

    onItemSelected(field,item){
        let fields = this.state.dynamicForm.slice();
        for(let f of fields){
            if(f.id === field.id){
                f.pickerOpened = false;
                f.value = item;
                break;
            }
        }
        this.setState({
            dynamicForm:fields
        })
    }


    renderColorField(field){
        return(
            <TextField
                id={"field_" + field.id}
                type={"color"}
                value={field.value}
                required={field.validationRules.map((item)=>{return item.name}).indexOf("required") !== -1}
                label={field.label}
                placeholder={field.placeholder}
                style={{width:"100%", marginBottom:15}}
                onChange={(event)=>{this.handleFieldValueChange(event,field)}}
                onKeyPress={(event)=>{this.onFieldKeyPress(event)}}
                margin="none"
                variant="outlined"
                error={field.error !== null}
                helperText={field.error ? field.error : null}
            />
        )
    }

    computeValidationRules(rawValidationsRules){
        let result = [];
        for(let rvr of rawValidationsRules){
            if(rvr === "required"){
                result.push({name:"required",data:null})
            }
            else if(rvr.indexOf("min_length_") !== -1){
                result.push({name:"min_length",data:parseInt(rvr.substring(11))})
            }
            else if(rvr.indexOf("max_length_") !== -1){
                result.push({name:"max_length",data:parseInt(rvr.substring(11))})
            }
            else if(rvr.indexOf("min_count_") !== -1){
                result.push({name:"min_count",data:parseInt(rvr.substring(10))})
            }
            else if(rvr.indexOf("max_count_") !== -1){
                result.push({name:"max_count",data:parseInt(rvr.substring(10))})
            }
            else if(rvr === "integer"){
                result.push({name:"integer",data:null})
            }
            else if(rvr === "positive"){
                result.push({name:"positive",data:null})
            }
            else if(rvr === "positive_strict"){
                result.push({name:"positive_strict",data:null})
            }
            else if(rvr === "negative"){
                result.push({name:"negative",data:null})
            }
            else if(rvr === "negative_strict"){
                result.push({name:"negative_strict",data:null})
            }
            else if(rvr === "min_date_today"){
                result.push({name:"min_date_today",data:null})
            }
            else if(rvr === "min_date_tomorrow"){
                result.push({name:"min_date_tomorrow",data:null})
            }
            else if(rvr === "max_date_today"){
                result.push({name:"max_date_today",data:null})
            }
            else if(rvr === "max_date_yesterday"){
                result.push({name:"max_date_yesterday",data:null})
            }
        }
        return result;
    }
}

HForm.defaultProps = {
    fields: {fields:[]},
}

export default HForm;
