import GTFSAPI from "../HomePage/api/GTFSAPI";

export const sidebarData = [
    {title: 'Accueil',
    path: '/home',
    icon: 'home',
    requireGTFS: false
    },
    {title: 'Calendrier',
        path: '/calendar',
        icon: 'edit_calendar',
        requireGTFS: true
    },
    {title: 'Filtrer routes',
        path: '/filter/routes',
        icon: 'directions_bus',
        requireGTFS: true
    },
]

export const sideBarDataDownload = [
    {
        title: 'Télécharger Logs',
        icon: 'download',
        actionDownload: GTFSAPI.exportFixesHistoric,
        requireGTFS: true
    },
    {
        title: 'Télécharger GTFS',
        icon: 'download',
        actionDownload: GTFSAPI.exportGTFS,
        requireGTFS: true
    }
]