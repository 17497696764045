import React from "react";
import GTFSAPI from "./api/GTFSAPI";
import Icon from "@material-ui/core/Icon";

export class Helpers {

    static filterRes(data) {
        let cloneData = {...data}
        delete cloneData.hasErrors
        delete cloneData.hasWarnings
        delete cloneData.agencyName
        let errors = {...cloneData}
        let warnings = {}

        if (errors.unusedStops) {
            warnings.unusedStops = errors.unusedStops
            delete errors.unusedStops
        }
        const e = Object.entries(errors)
        const w = Object.entries(warnings)
        return [e, w]
    }

    static translateErrors(error) {
        if (error === "tripsWithoutStopTimes") {
            return "Le GTFS a des trips qui ne possèdent pas de stop times. Vous pouvez les supprimer."
        }
        if (error === "tripsWithInvalidSequences") {
            return "Le GTFS a des séquences invalides."
        }
        if (error === "tripsWithInvalidSequenceTimes") {
            return "Le GTFS possède des incohérences entre les séquences et les heures de passage. Vous pouvez les supprimer"
        }
        if (error === "stopTimesWithoutStop") {
            return "Le GTFS a des arrêts sans stop."
        }
        if (error === "unusedStops") {
            return "Le GTFS a des arrêts inutilisés."
        }
    }

    static renderError(error) {
        let data = false
        if (error === "tripsWithoutStopTimes") {
            data = true
        }
        if (error === "tripsWithInvalidSequences") {
            data = false
        }
        if (error === "tripsWithInvalidSequenceTimes") {
            data = true
        }
        if (error === "stopTimesWithoutStop") {
            data = false
        }
        return data
    }

    static async deleteErrors(error) {
        if (error === "tripsWithoutStopTimes") {
            await GTFSAPI.deleteTripsWithoutStopTimes()
        }
        if (error === "tripsWithInvalidSequenceTimes") {
            await GTFSAPI.deleteTripsWithInvalidSequenceTimes()
        }
    }

    static renderIcon(type) {
        if (type === "unusedStops") {
            return (
                <Icon style={{color: "#F7CD29", fontSize: 30, paddingLeft: 10}}>warning_amber</Icon>
            )
        } else {
            return (
                <div style={{
                    display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', backgroundColor: "#DB3355",
                    height: 30, width: 30, borderRadius: '50%', marginLeft: 10
                }}>
                    <Icon style={{color: "#E6E6E6", fontSize: 18}}>highlight_off</Icon>
                </div>
            )
        }
    }

    static renderColor(type) {
        if (type === "unusedStops") {
            return (
                "#F7CD29"
            )}
        else {
        return "#DB3355"
        }
    }

}