import React, {Component} from 'react';
import './HListPicker.css';
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress, TextField, Icon
} from "@material-ui/core";
import VirtualList from 'react-tiny-virtual-list';
import {Link} from 'react-router-dom';
import {withTranslation} from "react-i18next";

/**
 * This component allows you to pick an element from a list and returns the element. It renders within a modal. The list is rendered with
 * react-tiny-virtual-list, allowing the list to be of extreme size
 *
 * The rendering of the list rows can only account for root properties (no deepness allowed)
 */
class HListPicker extends Component {

    constructor(props){
        super(props);

        this.state={
            searchText:"",
            items:this.props.items,
            sortedItems:this.sortItems(this.props.items),
            selectedItem:null
        };
    }

    static propTypes = {
        /** The title of the picker's modal */
        title: PropTypes.string,
        /** The body of the picker's modal */
        body: PropTypes.string,
        /** The list of element to pick from */
        items: PropTypes.array.isRequired,
        /** The properties of the array displayed on the list rows */
        renderItemKeys: PropTypes.string.isRequired,
        /** The properti=y to sort the elements by*/
        sortBy: PropTypes.string,
        /**
         * Called when user picks elements from the list
         * @return {any} The item selected from the list
         */
        onItemSelected: PropTypes.func.isRequired,
        /**
         * Called when user aborts element picking
         */
        onAbort: PropTypes.func.isRequired
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            items:nextProps.items,
            sortedItems:nextProps.items
        })
    }

    onAbort(){
        this.props.onAbort()
    }

    onItemSelected(){
        this.props.onItemSelected(this.state.selectedItem);
    }


    handleSearchTextChange(event){

        let searchText = event.target.value;

        let rawItems = this.props.items.slice();
        let newSortedItems = [];

        for(let item of rawItems){

            let matchFound = false;
            for(let property in item){
                if(typeof item[property] === 'string' || item[property] instanceof String){
                    if(item[property].toUpperCase().indexOf(searchText.toUpperCase()) !== -1){
                        matchFound = true;
                        break;
                    }
                }
            }
            if(matchFound === true){
                newSortedItems.push(item)
            }
        }

        newSortedItems = this.sortItems(newSortedItems);

        this.setState({
            searchText:searchText,
            sortedItems:newSortedItems
        });
    };

    sortItems(raw){
        if(this.props.sortBy){
            raw = raw.sort((a,b)=>{
                if(a[this.props.sortBy] < b[this.props.sortBy]){
                    return -1
                }
                else{
                    return 1;
                }
            });
        }
        return raw;
    }

    selectItem(item){
        this.setState({
            selectedItem:item
        })
    }

    goToNewItem(){

    }

    render(){
        return(
            <div style={{position:'fixed', top:0, right:0, width:0, height:0, zIndex:4000}} onClick={(e)=>{e.stopPropagation()}}>
                <Dialog
                    open={true}
                    onClose={()=>{this.onAbort()}}
                    aria-labelledby="h-alert-dialog-title"
                    aria-describedby="h-alert-dialog-description"
                >
                    <DialogTitle id="h-alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent style={{width:550}}>

                        <DialogContentText id="alert-dialog-description">{this.props.body}</DialogContentText>

                        {this.props.newItemButton &&
                            <Link to={this.props.newItemButton.url} target={"_blank"} style={{textDecoration: 'none'}}>
                                <Button size={"small"} variant={"contained"} style={{color:'#FFFFFF', backgroundColor:'#1079FC', marginTop:10, marginBottom:5}}
                                        onClick={()=>{}}>
                                    {this.props.newItemButton.text}
                                    <Icon style={{color:'#FFFFFF', fontSize:18, marginLeft:5}}>add</Icon>
                                </Button>
                            </Link>
                        }

                        {this.props.items.length === 0 &&
                        <div style={{height:50, width:"100%", borderRadius:6, border:"1px solid #CCCCCC", display:"flex",
                            flexDirection:"row", justifyContent:"flex-start", alignItems:'center', padding:10, marginTop:10, backgroundColor:"#FAFAFA"}}>
                            <CircularProgress size={25} style={{color:'#1079FC', marginRight:10}}/>
                            <p style={{color:'#555555', textAlign:"left", margin:0}}>Chargement de la liste en cours...</p>
                        </div>
                        }

                        {this.props.items.length > 0 &&
                            <div style={{width:"100%", marginTop:10}}>
                                <div style={{width:"100%", borderRadius:6, border:"1px solid #CCCCCC", overflow:"hidden"}}>

                                    <div style={{height:46, width:"100%", borderBottom:"1px solid #CCCCCC", display:"flex", flexDirection:"row",
                                        justifyContent:"flex-start", alignItems:'center', paddingLeft:10, paddingRight:4}}>

                                        <p style={{display:"flex",flex:1, margin:0, paddingRight:10, textAlign:"left", color:this.state.selectedItem ? "#1079FC" : '#555555',
                                            fontWeight:this.state.selectedItem ? "bold" : "normal", fontSize:14}}>
                                            {this.state.selectedItem ? "1 élément sélectionné" : "Aucun sélectionné"}
                                        </p>

                                        <TextField
                                            className={"search-outlined"}
                                            placeholder={"Rechercher"}
                                            style={{height:38, padding:0, width:300}}
                                            disabled={this.props.items.length === 0}
                                            InputProps={{
                                                style: {
                                                    height:38,
                                                    color:'#222222'
                                                }
                                            }}
                                            value={this.state.searchText}
                                            onChange={(event)=>{this.handleSearchTextChange(event)}}
                                            margin="none"
                                            variant="outlined"
                                        />


                                    </div>
                                    <div style={{width:"100%", height:340, overflowY:"auto", backgroundColor:'#FAFAFA'}}>

                                        <VirtualList
                                            width='100%'
                                            height={340}
                                            itemCount={this.state.sortedItems.length}
                                            itemSize={40}
                                            overscanCount={40}
                                            renderItem={({index, style}) =>{

                                                let item = this.state.sortedItems[index];

                                                return(
                                                    <div key={index} style={style}>

                                                        {this.props.renderItemRow &&
                                                            <div style={{width:"100%", height:40, overflow:'hidden'}} onClick={()=>{this.selectItem(item)}}  className={"selectable-item"}>
                                                                {this.props.renderItemRow(item)}
                                                            </div>
                                                        }

                                                        {!this.props.renderItemRow &&
                                                        <div style={{width:"100%", height:40, display:"flex", justifyContent:"flex-start",
                                                            alignItems:"center", paddingLeft:10, paddingRight:0}} className={"selectable-item"} key={"item"+index}
                                                             onClick={()=>{this.selectItem(item)}}>

                                                            <div style={{width:"calc(100% - 40px)", height:"100%", overflow:"hidden", display:"flex", justifyContent:"flex-start",
                                                                alignItems:'center'}}>
                                                                <p style={{margin:0, width:"calc(100% - 40px)", textAlign:"left", color:'#222222', fontSize:14}}>
                                                                    {this.props.renderItemKeys.map((key,index)=>{
                                                                        return(
                                                                            <span style={{marginRight:10}} key={index + "k"}>{item[key]}</span>
                                                                        )
                                                                    })}
                                                                </p>
                                                            </div>

                                                            <div style={{height:40, width:40, display:"flex", justifyContent:"center", alignItems:'center'}}>
                                                                {this.state.selectedItem && this.state.selectedItem.id === item.id &&
                                                                <Icon style={{color:'#1079FC', fontSize:20}}>check_box</Icon>
                                                                }

                                                            </div>
                                                        </div>
                                                        }

                                                    </div>
                                                )
                                            }}>
                                        </VirtualList>

                                    </div>

                                </div>
                            </div>
                        }

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={()=>{this.onAbort()}} color="primary">
                            {"Annuler"}
                        </Button>
                        <Button onClick={()=>{this.onItemSelected()}}
                                color="primary" disabled={!this.state.selectedItem}>
                            <span>{"Sélectionner"}</span>
                        </Button>
                    </DialogActions>


                </Dialog>
            </div>
        )
    }
}

HListPicker.defaultProps = {
    title:"",
    body:"",
    renderItemKeys:[],
    sortBy:"",
    items:[]
}

export default withTranslation()(HListPicker)
